import { useEffect, useState } from 'react';
import './index.scss';
import {
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  styled,
  InputAdornment,
  IconButton,
  Card,
  Autocomplete
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import UserService from '@services/user.service';

const Title = styled(Typography)(() => ({
  fontSize: '1rem',
  marginBottom: '10px'
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  '&.Mui-selected': {
    color: '#199F56',
    backgroundColor: 'white',
    border: '2px solid #199F56 !important',
    '&:hover': {
      backgroundColor: 'white',
      border: '2px solid #199F56 !important',
    }
  },
  '&:not(.Mui-selected)': {
    color: '#757575',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      border: '1px solid #ccc'
    }
  },
  textTransform: 'none',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-middleButton, & .MuiToggleButtonGroup-lastButton': {
    marginLeft: '0px !important'
  },
}));

interface ProjectContractItem {
  ProjectId: number;
  ProjectName: string;
  Contract: Array<{
    ContractId: number;
    ContractName: string;
  }>;
}

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [title, setTitle] = useState('Mr');
  const [accessToMEX, setAccessToMEX] = useState('Yes');
  const [companies, setCompanies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [projectContractList, setProjectContractList] = useState<ProjectContractItem[]>([]);
  const [passwordError, setPasswordError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [businessRegistrationNoError, setBusinessRegistrationNoError] = useState('');
  const [organisationNameError, setOrganisationNameError] = useState('');
  const [organisationShortNameError, setOrganisationShortNameError] = useState<any>('');
  const [projectError, setProjectError] = useState<any>('');
  const [contractError, setContractError] = useState<any>('');
  const [rolePositionError, setRolePositionError] = useState<any>('');
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    organisationName: '',
    organisationShortName: '',
    businessRegistrationNo: '',
    rolePosition: '',
    project: '',
    contract: ''
  });

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  const handleTitle = (event: any, newTitle: any): void => {
    setTitle(newTitle);
  };

  const handleAccessChange = (event: any, newAccess: any): void => {
    setAccessToMEX(newAccess);
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = event.target;

    setFormData(prev => ({
      ...prev,
      [id]: value
    }));

    switch (id) {
      case 'firstName':
        if (value) setFirstNameError('');
        break;
      case 'lastName':
        if (value) setLastNameError('');
        break;
      case 'email':
        if (value) setEmailError('');
        break;
      case 'password':
        if (value && validatePassword(value)) setPasswordError('');
        break;
      case 'businessRegistrationNo':
        if (value) setBusinessRegistrationNoError('');
        break;
      case 'organisationName':
        if (value) setOrganisationNameError('');
        break;
      case 'organisationShortName':
        if (value) setOrganisationShortNameError('');
        break;
      case 'rolePosition':
        if (value) setRolePositionError('');
        break;
      case 'project':
        if (value) setProjectError('');
        break;
      case 'contract':
        if (value) setContractError('');
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (event: any): void => {
    const name = event.target.name as string;
    const value = event.target.value;

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleOrganizationChange = async (event: any, newValue: any): Promise<void> => {
    const value = newValue ? newValue.uuid : '';
    setFormData(prev => ({
      ...prev,
      organisationName: value,
      organisationShortName: value,
      project: '',
      contract: ''
    }));

    if (value) {
      const data: any = await UserService.RegisterUser(value);
      setProjectContractList(data);
    } else {
      setProjectContractList([]);
    }
  };

  const handleShortNameChange = async (event: any): Promise<void> => {
    const value = event.target.value;
    setFormData(prev => ({
      ...prev,
      organisationName: value,
      organisationShortName: value,
      project: '',
      contract: ''
    }));

    if (value) {
      const data: any = await UserService.RegisterUser(value);
      setProjectContractList(data);
    } else {
      setProjectContractList([]);
    }
  };

  const handleProjectChange = (event: any): void => {
    const { name, value } = event.target;

    setFormData(prev => ({
      ...prev,
      [name]: value,
      contract: ''
    }));
  };

  const handleContractChange = (event: any): void => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (): Promise<void> => {
    let hasError = false;
    if (!formData.firstName) {
      setFirstNameError('First name cannot be empty');
      hasError = true;
    }
    if (!formData.lastName) {
      setLastNameError('Last name cannot be empty');
      hasError = true;
    }
    if (!formData.email) {
      setEmailError('Email cannot be empty');
      hasError = true;
    }
    if (!formData.password || !validatePassword(formData.password)) {
      setPasswordError('Password does not meet the requirements');
      hasError = true;
    }
    if (!formData.organisationName) {
      setOrganisationNameError('Organisation name cannot be empty');
      hasError = true;
    }
    if (!formData.organisationShortName) {
      setOrganisationShortNameError('Organisation short name cannot be empty');
      hasError = true;
    }
    if (!formData.businessRegistrationNo) {
      setBusinessRegistrationNoError('Business registration number cannot be empty');
      hasError = true;
    }
    if (!formData.rolePosition) {
      setRolePositionError('Position cannot be empty');
      hasError = true;
    }
    if (!formData.project) {
      setProjectError('Project cannot be empty');
      hasError = true;
    }
    if (!formData.contract) {
      setContractError('Contract cannot be empty');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const oktaParams = {
      profile: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        login: formData.email,
        mobilePhone: '1231231123'
      },
      credentials: {
        password: {
          value: formData.password
        }
      }
    };

    const oktaRes: any = await UserService.oktaReg(oktaParams);

    // const oktaAuthParams = {
    //   id: oktaRes.id,
    //   scope: 'USER',
    //   credentials: {
    //     userName: formData.email
    //   }
    // };

    // const oktaAuthRes: any = await UserService.oktaAuthorization('0oa99pp1fhUjqK3xV697', oktaAuthParams);
    await UserService.assignUserToApp('0oa99pp1fhUjqK3xV697', oktaRes.id);
    // await UserService.assignRoleToUser(oktaRes.id, formData.rolePosition);
    await UserService.addUserToGroup('00g99pup684aR2yfe697', oktaRes.id);
    console.log(oktaRes.id, 'oktaRes');
    const selectedRole: any = roles.find((role: any) => role.id === formData.rolePosition);
    const submitData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      title: title,
      password: formData.password,
      ciamUserId: oktaRes.id,
      position: selectedRole ? selectedRole.displayName : '',
      organization: {
        organizationId: formData.organisationName,
        registrationNo: formData.businessRegistrationNo
      },
      project: {
        projectId: formData.project
      },
      contract: {
        contractId: Number(formData.contract)
      },
      role: {
        roleId: formData.rolePosition
      }
    };

    const res = await UserService.CreateUser(submitData);

    if (res) {
      window.location.href = window.location.origin + '/login';
    } else {
      UserService.deleteUser(oktaRes.id);
    }

  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const data: any = await UserService.GetLimitList();
      setCompanies(data);


      if (data.length > 0) {
        setFormData(prev => ({
          ...prev,
          organisationName: '',
          organisationShortName: ''
        }));
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchRoleList = async () => {
      const data: any = await UserService.GetAllRoleList();
      setRoles(data);
    };

    fetchRoleList();
  }, []);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const redirected = urlParams.get('redirected');

  //   if (!redirected) {
  //     setTimeout(() => {
  //       window.location.href = 'http://localhost:3000/register?redirected=true';
  //     }, 300);
  //   } else {
  //     setLoading(false);
  //     fetchCompanies();
  //     fetchRoleList();
  //   }
  // }, []);

  // const fetchCompanies = async () => {
  //   const data: any = await UserService.GetLimitList();
  //   setCompanies(data);
  //   if (data.length > 0) {
  //     setFormData(prev => ({
  //       ...prev,
  //       organisationName: '',
  //       organisationShortName: ''
  //     }));
  //   }
  // };

  // const fetchRoleList = async () => {
  //   const data: any = await UserService.GetAllRoleList();
  //   setRoles(data);
  // };

  // if (loading) {
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  const validatePassword = (password: string): boolean => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };

  return (
    <div className='register-container'>
      <div className="new-submission-header">
        <Title style={{ color: "#199f56", fontWeight: 700, marginLeft: 60, fontSize: '2rem' }}>
          Registration
        </Title>
      </div>
      <Card sx={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Personal Information</Typography>
            <Title>Must be the same as the information on the identity document.</Title>
          </Grid>
          <Grid item xs={24}>
            <FormControl fullWidth>
              <Title>Title</Title>
              <StyledToggleButtonGroup
                value={title}
                exclusive
                onChange={handleTitle}
                aria-label="text alignment"
                defaultValue="Mr"
              >
                <StyledToggleButton value="Ar" aria-label="Ar">Ar</StyledToggleButton>
                <StyledToggleButton value="Dr" aria-label="Dr">Dr</StyledToggleButton>
                <StyledToggleButton value="Ir" aria-label="Ir">Ir</StyledToggleButton>
                <StyledToggleButton value="JP" aria-label="JP">JP</StyledToggleButton>
                <StyledToggleButton value="Miss" aria-label="Miss">Miss</StyledToggleButton>
                <StyledToggleButton value="Mr" aria-label="Mr">Mr</StyledToggleButton>
                <StyledToggleButton value="Ms" aria-label="Ms">Ms</StyledToggleButton>
                <StyledToggleButton value="Mrs" aria-label="Mrs">Mrs</StyledToggleButton>
              </StyledToggleButtonGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Title>Last Name</Title>
            <FormControl fullWidth>
              <TextField
                id="lastName"
                value={formData.lastName}
                onChange={handleTextFieldChange}
                variant="outlined"
                error={Boolean(lastNameError)}
                helperText={lastNameError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <Title>First Name</Title>
              <TextField
                id="firstName"
                value={formData.firstName}
                onChange={handleTextFieldChange}
                variant="outlined"
                error={Boolean(firstNameError)}
                helperText={firstNameError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <Title>Email Address</Title>
              <TextField
                id="email"
                value={formData.email}
                onChange={handleTextFieldChange}
                variant="outlined"
                error={Boolean(emailError)}
                helperText={emailError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <Title>Password</Title>
              <TextField
                id="password"
                value={formData.password}
                onChange={handleTextFieldChange}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                error={Boolean(passwordError)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                helperText="At least 8 characters and requires at least 1 lowercase, 1 uppercase, 1 number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>Organisation Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Title>Organisation Name (Full Name)</Title>
              <Autocomplete
                id="organisationName"
                options={companies}
                getOptionLabel={(option: any) => option.companyName}
                value={companies.find((company: any) => company.uuid === formData.organisationName) || null}
                onChange={handleOrganizationChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    error={Boolean(organisationNameError)}
                    helperText={organisationNameError}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Title>Organisation Name (Short Form)</Title>
              <Select
                name="organisationShortName"
                value={formData.organisationShortName}
                onChange={handleShortNameChange}
                error={Boolean(organisationShortNameError)}
              >
                {companies.map((company: any) => (
                  <MenuItem key={company.uuid} value={company.uuid}>
                    {company.shortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <Title>Business Registration No.</Title>
              <TextField
                id="businessRegistrationNo"
                value={formData.businessRegistrationNo}
                onChange={handleTextFieldChange}
                variant="outlined"
                error={Boolean(businessRegistrationNoError)}
                helperText={businessRegistrationNoError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Title>Role / Position</Title>
              <Select
                name="rolePosition"
                value={formData.rolePosition}
                onChange={handleSelectChange}
                variant="outlined"
                error={Boolean(rolePositionError)}
              >
                {roles.map((role: any) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>Project & Contract Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <Title>Project</Title>
              <Select
                name="project"
                value={formData.project}
                onChange={handleProjectChange}
                error={Boolean(projectError)}
              >
                {projectContractList.map((item) => (
                  <MenuItem key={item.ProjectId} value={item.ProjectId}>
                    {item.ProjectName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Title>Contract</Title>
              <Select
                name="contract"
                value={formData.contract}
                onChange={handleContractChange}
                error={Boolean(contractError)}
              >
                {projectContractList
                  .find(project => project.ProjectId === Number(formData.project))
                  ?.Contract.map(contract => (
                    <MenuItem
                      key={contract.ContractId}
                      value={contract.ContractId.toString()}
                    >
                      {contract.ContractName}
                    </MenuItem>
                  )) || []}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>Account Set Up</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledToggleButtonGroup
              exclusive
              defaultValue="yes"
              value={accessToMEX}
              onChange={handleAccessChange}
              aria-label="Access to MEX"
            >
              <StyledToggleButton value="Yes" aria-label="Yes">
                Yes
              </StyledToggleButton>
              <StyledToggleButton value="No" aria-label="No">
                No
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <Button variant="outlined" style={{ width: '240px', textTransform: 'none', color: '#199F56', border: '1px solid #199F56'}}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{ width: '240px', backgroundColor: '#199F56', marginLeft: '20px', textTransform: 'none' }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default Register;
