import { GlobalStateProvider } from '@providers/globalStore'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import CustomSpinner from './components/CustomSpinner'
import MainRoutes from './routes/routes'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FormStateProvider } from '@providers/formStateProvider'
import moment from 'moment'
import ScrollToTop from '@utils/scrollToTop'
import { LanguageContext, LanguageProvider } from '@providers/languageProvider'
// import { ReactKeycloakProvider } from '@react-keycloak/web'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react'
import { useTranslation } from 'react-i18next'
// import keycloak from './keycloak'
import jwt_decode from 'jwt-decode'
import UserService from '@services/user.service'
import useLocalStorage from '@hooks/useLocalStorage'
import { InitUserInfo, UserInfo } from '@services/model/user.model'
import config from './config'
import { WifiTetheringErrorRoundedTwoTone } from '@mui/icons-material'
import CorsErrorModal from '@components/Login/CorsErrorModal'
import AuthRequiredModal from '@components/Login/AuthRequiredModal'
import { I18nextProvider } from 'react-i18next'
import i18n, { LanguageEnum } from './i18n'
import * as locales from '@mui/material/locale'
import { createTheme, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
// import '@thirdParty/userSnap'
// Default Moment Format
moment.defaultFormat = 'D MMMM YYYY, HH:mm'

const oktaAuth = new OktaAuth(config.oidc)

export default function App() {
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const [corsErrorModalOpen, setCorsErrorModalOpen] = useState(false)
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false)

  const triggerLogin = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    window.location.replace('/login')
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const customAuthHandler = async () => {
    // TODO: set user
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin()
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true)
    }
  }

  const { i18n: i18next } = useTranslation()
  const { language } = i18next

  const theme = (locale: string) => {
    let currentLocale = locales.enUS
    switch (locale) {
      case LanguageEnum.TRADITIONAL_CHINESE:
        currentLocale = locales.zhHK
        break
      case LanguageEnum.SIMPLIFIED_CHINESE:
        currentLocale = locales.zhCN
        break
    }
    return createTheme(currentLocale)
  }

  useEffect(() => {
    document.documentElement.setAttribute('lang', language)
  }, [language])

  const themeWithLocale = useMemo(() => theme(language), [language, theme])

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}>
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
      <GlobalStateProvider>
        <I18nextProvider i18n={i18n}>
          <LanguageProvider>
            <FormStateProvider>
              <BrowserRouter>
                <ScrollToTop />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ThemeProvider theme={themeWithLocale}>
                    <Suspense fallback={<CustomSpinner />}>
                      <MainRoutes {...{ setCorsErrorModalOpen }} />
                    </Suspense>
                  </ThemeProvider>
                </LocalizationProvider>
              </BrowserRouter>
            </FormStateProvider>
          </LanguageProvider>
        </I18nextProvider>
      </GlobalStateProvider>
    </Security>
  )
}