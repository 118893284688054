import { Fragment, useEffect, useRef, CSSProperties } from 'react'
import SignaturePad from 'react-signature-canvas'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, Typography } from '@mui/material'
import { FieldError } from 'react-hook-form'
import { RemoveBase64Header } from '@utils/file'
import { useTranslation } from 'react-i18next'

interface formSignaturePadProps {
  onChange?: (...event: any[]) => void
  value?: string
  error?: FieldError
  sx?: CSSProperties
  width?: string | number | undefined
  height?: string | number | undefined
  disabled?: boolean
  uneditable?: boolean
}

const SignaturePadComponent = SignaturePad as any;

const FormSignaturePad = (props: formSignaturePadProps) => {
  const signContainer = useRef<any>()
  const { t } = useTranslation()
  useEffect(() => {
    if (signContainer.current && props.value) {
      signContainer.current.fromDataURL(props.value, {
        width: 300,
        height: 150,
      })
    }
  }, [props.value])

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const formatSignature = () => {
    if (signContainer.current) {
      const dataURL = signContainer.current.getTrimmedCanvas().toDataURL('image/png')
      return RemoveBase64Header(dataURL)
    }
  }

  const removeSignature = () => {
    if (signContainer.current) {
      signContainer.current.clear()
      props.onChange!(undefined)
    }
  }

  return (
    <Fragment>
      {props.disabled || props.uneditable ? (
        <Fragment>
          {props.value ? (
            <img
              src={props.value}
              alt="signature"
              style={{
                width: props.width ?? 300,
                height: props.height ?? 150,
                borderBottom: '1px solid rgb(50, 50, 50)',
                ...props.sx,
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: props.width ?? 300,
                height: props.height ?? 150,
                borderBottom: '1px solid rgb(50, 50, 50)',
                ...props.sx,
              }}>
              <Typography>{t('No Signature')}</Typography>
            </Box>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <SignaturePadComponent
            ref={signContainer}
            onEnd={() => props.onChange!(formatSignature())}
            penColor="#000000"
            canvasProps={{
              width: (props.sx && props.sx.width) ?? 300,
              height: (props.sx && props.sx.height) ?? 150,
              style: props.error
                ? { border: '1px solid red', borderRadius: '25px' }
                : { borderBottom: '1px solid rgb(50, 50, 50)' },
            }}
          />
          <IconButton onClick={removeSignature}>
            <ClearIcon />
          </IconButton>
        </Fragment>
      )}
    </Fragment>
  )
}

export default FormSignaturePad