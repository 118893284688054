import MainLayout from '@components/MainLayout'
import { useRoutes } from 'react-router-dom'
import { ProtectedRoute } from './protectedRoute'
import LoginPage from '@pages/Login'
import NotificationPage from '@pages/Notifications'
import LandingPage from '@pages/Landing'
import DashboardProjectPage from '@pages/Dashboard/Project'
import DashboardCicPage from '@pages/Dashboard/Cic'
import UnauthorizedPage from '@pages/401'

// Daily Record System DRS
import DailyRecordSummaryMyRecordsPage from '@pages/DailyRecordSummary/MyRecords'
import DailyRecordSummaryAllRecordsPage from '@pages/DailyRecordSummary/AllRecords'
import DailyRecordSummaryFormPage from '@pages/DailyRecordSummary/Form'
import DailyRecordSummaryUploadAllRecordsPage from '@pages/DailyRecordSummary/Upload/AllRecords'
import DailyRecordSummaryUploadFormPage from '@pages/DailyRecordSummary/Upload/Form'

import DailyRecordSystemItemBatchPage from '@pages/DailyRecordSummaryItem/Batch'
import DailyRecordSystemItemCEasyPage from '@pages/DailyRecordSummaryItem/CEasy'

import DailyRecordSystemItemSinglePage from '@pages/DailyRecordSummaryItem/Single'
import DailyRecordSystemItemAllRecordsPage from '@pages/DailyRecordSummaryItem/AllRecords'

// Monthly Waste Flow Table MWF
import MonthlyWasteFlowTableMyRecordsPage from '@pages/MonthlyWasteFlowTable/MyRecords'
import MonthlyWasteFlowTableAllRecordsPage from '@pages/MonthlyWasteFlowTable/AllRecords'
import MonthlyWasteFlowTableFormPage from '@pages/MonthlyWasteFlowTable/Form'

// Monthly Report MR

import MonthlyReport from '@pages/MonthlyReport/Form'
import MonthlyReportAllRecordPage from '@pages/MonthlyReport/AllRecords'

// Waste Forecast Table WFT
import WasteForecastTableFormPage from '@pages/WasteForecastTable/Form'
import WasteForecastTableAllRecordsPage from '@pages/WasteForecastTable/AllRecords'
import MonthlyWasteFlowTableUploadFormPage from '@pages/MonthlyWasteFlowTable/Upload/Form'

// Waste Management Plan WMP
import WasteManagementPlanFormPage from '@pages/WasteManagementPlan/Form'
import WasteManagementPlanAllRecordsPage from '@pages/WasteManagementPlan/AllRecords'
import WasteManagementPlanMyRecordsPage from '@pages/WasteManagementPlan/MyRecords'
import WasteManagementPlanTemplatePage from '@pages/WasteManagementPlan/Template'

//admin
import AdminSubmitFormPage from '@pages/Admin/Submit/Form'
import AdminPage from '@pages/Admin/index'
import AdminUserAllRecordsPage from '@pages/Admin/User/AllRecords'
import AdminUserFormPage from '@pages/Admin/User/Form'
import AdminProjectFormPage from '@pages/Admin/Project/Form'
import AdminProjectAllRecordsPage from '@pages/Admin/Project/AllRecords'
import AdminContractFormPage from '@pages/Admin/Contract/Form'
import AdminContractAllRecordsPage from '@pages/Admin/Contract/AllRecords'
import AdminOrganizationFormPage from '@pages/Admin/Organization/Form'
import AdminOrganizationAllRecordsPage from '@pages/Admin/Organization/AllRecords'

import { RightsCategory } from '@services/model/form/form.model'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'
import getUserRightByRole from '@utils/getUserRightByRole'
import {
  canCreateProjAssignOrgUsersRoles,
  cicAdminRoles,
  hasUserAdminRightRoles,
  canCreateOrgUserRoles,
} from '@services/model/admin.model'
import Login from '@pages/Login'
import Logout from '@pages/Logout'
import { LoginCallback } from '@okta/okta-react'
import Register from '@pages/Register'

const MainRoutes = ({ setCorsErrorModalOpen }) => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)

  const { hasRightByCatAndCode } = useContext(GlobalContext)
  const projectRight = hasRightByCatAndCode(RightsCategory.Project, ['C', 'R', 'U', 'D'])
  const epdRight = hasRightByCatAndCode(RightsCategory.EpdSubmission, ['C', 'R', 'U', 'D'])
  const contractRight = hasRightByCatAndCode(RightsCategory.Contract, ['C', 'R', 'U', 'D'])
  const userRight = hasRightByCatAndCode(RightsCategory.User, ['C', 'R', 'U', 'D'])
  const organizationRight = hasRightByCatAndCode(RightsCategory.Organization, ['C', 'R', 'U', 'D'])

  const { hasUserRight: hasViewAdminPageRight } = getUserRightByRole(userInfo, globalState, [
    ...cicAdminRoles,
    ...canCreateOrgUserRoles,
    ...canCreateProjAssignOrgUsersRoles,
    ...hasUserAdminRightRoles,
  ])

  const { hasUserRight: cicAdminRights } = getUserRightByRole(userInfo, globalState, cicAdminRoles)

  const { hasUserRight: showAllOrgRecords } = getUserRightByRole(
    userInfo,
    globalState,
    canCreateOrgUserRoles,
  )

  const isCicUser = userInfo?.organization?.companyName === 'Construction Industry Council'
  const isSystemUser = userInfo?.organization?.companyName === 'System'

  const { hasUserRight: hasViewContProjRight } = getUserRightByRole(userInfo, globalState, [
    ...cicAdminRoles,
    ...canCreateProjAssignOrgUsersRoles,
    ...canCreateOrgUserRoles,
  ])

  const { hasUserRight: hasUserAdminRight } = getUserRightByRole(
    userInfo,
    globalState,
    hasUserAdminRightRoles,
  )

  const routeResult = useRoutes([
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/login/callback',
      element: <LoginCallback />,
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          children: [
            {
              path: '/',
              element: <ProtectedRoute children={<LandingPage />} />,
            },
          ],
        },
        {
          path: '/dashboard',
          children: [
            {
              path: '/dashboard/project',
              element: <ProtectedRoute children={<DashboardProjectPage />} />,
            },
            {
              path: '/dashboard/cic',
              element: <ProtectedRoute children={<DashboardCicPage />} />,
            },
          ],
        },

        {
          path: '/admin',
          children: [
            {
              path: '/admin/',
              element: (
                <ProtectedRoute children={<AdminPage />} permission={hasViewAdminPageRight} />
              ),
            },
            {
              path: '/admin/submit',
              element: (
                <ProtectedRoute
                  children={<AdminSubmitFormPage />}
                  permission={
                    cicAdminRights || (hasViewAdminPageRight && (isSystemUser || isCicUser))
                  }
                />
              ),
            },
            {
              path: '/admin/organization/all-records',
              element: (
                <ProtectedRoute
                  children={<AdminOrganizationAllRecordsPage />}
                  permission={cicAdminRights || showAllOrgRecords}
                />
              ),
            },
            {
              path: '/admin/organization',
              element: (
                <ProtectedRoute
                  children={<AdminOrganizationFormPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },
            {
              path: '/admin/organization/:id',
              element: (
                <ProtectedRoute
                  children={<AdminOrganizationFormPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },
            {
              path: '/admin/contract/all-records',
              element: (
                <ProtectedRoute
                  children={<AdminContractAllRecordsPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },

            {
              path: '/admin/contract',
              element: (
                <ProtectedRoute
                  children={<AdminContractFormPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },
            {
              path: '/admin/contract/:id',
              element: (
                <ProtectedRoute
                  children={<AdminContractFormPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },
            {
              path: '/admin/project/all-records',
              element: (
                <ProtectedRoute
                  children={<AdminProjectAllRecordsPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },

            {
              path: '/admin/project',
              element: (
                <ProtectedRoute
                  children={<AdminProjectFormPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },
            {
              path: '/admin/project/:id',
              element: (
                <ProtectedRoute
                  children={<AdminProjectFormPage />}
                  permission={hasViewContProjRight}
                />
              ),
            },
            {
              path: '/admin/user',
              element: (
                <ProtectedRoute children={<AdminUserFormPage />} permission={hasUserAdminRight} />
              ),
            },
            {
              path: '/admin/user/all-records',
              element: (
                <ProtectedRoute
                  children={<AdminUserAllRecordsPage />}
                  permission={hasUserAdminRight}
                />
              ),
            },
            {
              path: '/admin/user/:id',
              element: <ProtectedRoute children={<AdminUserFormPage />} permission={true} />,
            },
          ],
        },
        {
          path: '/daily-record-summary',
          children: [
            {
              path: '/daily-record-summary',
              element: <ProtectedRoute children={<DailyRecordSummaryFormPage />} />,
            },
            {
              path: '/daily-record-summary/:id',
              element: <ProtectedRoute children={<DailyRecordSummaryFormPage />} />,
            },
            {
              path: '/daily-record-summary/overview',
              element: <ProtectedRoute children={<DailyRecordSummaryFormPage />} />,
            },
            {
              path: '/daily-record-summary/dashboard',
              element: <ProtectedRoute children={<DailyRecordSummaryFormPage />} />,
            },
            {
              path: '/daily-record-summary/my-records',
              element: <ProtectedRoute children={<DailyRecordSummaryMyRecordsPage />} />,
            },
            {
              path: '/daily-record-summary/all-records',
              element: <ProtectedRoute children={<DailyRecordSummaryAllRecordsPage />} />,
            },
          ],
        },
        {
          path: '/daily-record-summary-item',
          children: [
            {
              path: '/daily-record-summary-item',
              element: <ProtectedRoute children={<DailyRecordSystemItemSinglePage />} />,
            },
            {
              path: '/daily-record-summary-item/:id',
              element: <ProtectedRoute children={<DailyRecordSystemItemSinglePage />} />,
            },
            {
              path: '/daily-record-summary-item/batch',
              element: <ProtectedRoute children={<DailyRecordSystemItemBatchPage />} />,
            },
            {
              path: '/daily-record-summary-item/c-easy',
              element: <ProtectedRoute children={<DailyRecordSystemItemCEasyPage />} />,
            },
            {
              path: '/daily-record-summary-item/all-records',
              element: <ProtectedRoute children={<DailyRecordSystemItemAllRecordsPage />} />,
            },
          ],
        },
        {
          path: '/daily-record-summary-upload',
          children: [
            {
              path: '/daily-record-summary-upload/all-records',
              element: <ProtectedRoute children={<DailyRecordSummaryUploadAllRecordsPage />} />,
            },
            {
              path: '/daily-record-summary-upload/',
              element: <ProtectedRoute children={<DailyRecordSummaryUploadFormPage />} />,
            },
            {
              path: '/daily-record-summary-upload/:id',
              element: <ProtectedRoute children={<DailyRecordSummaryUploadFormPage />} />,
            },
          ],
          // monthly-waste-flow-table-upload MWFUploadForm
        },
        {
          path: '/monthly-waste-flow-table-upload',
          children: [
            {
              path: '/monthly-waste-flow-table-upload',
              element: <ProtectedRoute children={<MonthlyWasteFlowTableUploadFormPage />} />,
            },
            {
              path: '/monthly-waste-flow-table-upload/:id',
              element: <ProtectedRoute children={<MonthlyWasteFlowTableUploadFormPage />} />,
            },
          ],
        },
        {
          path: '/monthly-waste-flow-table',
          children: [
            {
              path: '/monthly-waste-flow-table',
              element: <ProtectedRoute children={<MonthlyWasteFlowTableFormPage />} />,
            },
            {
              path: '/monthly-waste-flow-table/:id',
              element: <ProtectedRoute children={<MonthlyWasteFlowTableFormPage />} />,
            },

            {
              path: '/monthly-waste-flow-table/my-records',
              element: <ProtectedRoute children={<MonthlyWasteFlowTableMyRecordsPage />} />,
            },
            {
              path: '/monthly-waste-flow-table/all-records',
              element: <ProtectedRoute children={<MonthlyWasteFlowTableAllRecordsPage />} />,
            },
          ],
        },

        {
          path: '/waste-forecast-table',
          children: [
            {
              path: '/waste-forecast-table',
              element: <ProtectedRoute children={<WasteForecastTableFormPage />} />,
            },
            {
              path: '/waste-forecast-table/:id',
              element: <ProtectedRoute children={<WasteForecastTableFormPage />} />,
            },
            {
              path: '/waste-forecast-table/all-records',
              element: <ProtectedRoute children={<WasteForecastTableAllRecordsPage />} />,
            },
          ],
        },

        {
          path: '/waste-management-plan',
          children: [
            {
              path: '/waste-management-plan',
              element: <ProtectedRoute children={<WasteManagementPlanFormPage />} />,
            },
            {
              path: '/waste-management-plan/:id',
              element: <ProtectedRoute children={<WasteManagementPlanFormPage />} />,
            },
            {
              path: '/waste-management-plan/my-records',
              element: <ProtectedRoute children={<WasteManagementPlanMyRecordsPage />} />,
            },
            {
              path: '/waste-management-plan/all-records',
              element: <ProtectedRoute children={<WasteManagementPlanAllRecordsPage />} />,
            },
          ],
        },

        {
          path: '/waste-management-plan-template',
          children: [
            {
              path: '/waste-management-plan-template',
              element: <ProtectedRoute children={<WasteManagementPlanTemplatePage />} />,
            },
            {
              path: '/waste-management-plan-template/:id',
              element: <ProtectedRoute children={<WasteManagementPlanTemplatePage />} />,
            },
          ],
        },
        {
          path: '/monthly-report',
          children: [
            {
              path: '/monthly-report',
              element: <ProtectedRoute children={<MonthlyReport />} />,
            },
            {
              path: '/monthly-report/:id',
              element: <ProtectedRoute children={<MonthlyReport />} />,
            },
            {
              path: '/monthly-report/all-records',
              element: <ProtectedRoute children={<MonthlyReportAllRecordPage />} />,
            },
          ],
        },
        {
          path: '/notifications',
          element: <ProtectedRoute children={<NotificationPage />} />,
        },
      ],
    },
    {
      path: '/401',
      element: <UnauthorizedPage />,
    },
  ])

  return routeResult
}

export default MainRoutes
