import { KeyValPair } from '@models/Common'
import apiGateway from '@utils/axiosInterceptor'
import { GroupType } from './model/group.model'
import { UserInfo } from './model/user.model'
import axios from 'axios'

const apiToken = process.env.REACT_APP_API_KEY;

const GetUserInfo = async (email: string) => {
  return await apiGateway.get<UserInfo>(`/api/v1/users/info?email=${email}`).then((rep) => {
    return rep.data
  })
}

const CreateUser = async (params: any) => {
  return await apiGateway.post<UserInfo>('/api/v1/users/create', params).then((rep) => {
    return rep.data
  })
}

const RegisterUser = async (organizationId: any) => {
  return await apiGateway.get<UserInfo>(`/api/v1/project/getProjectByOrganizationId?organizationId=${organizationId}`).then((rep) => {
    return rep.data
  })
}

const GetRoleMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/roles/meta').then((rep) => {
    return rep.data
  })
}

const GetUserMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/meta').then((rep) => {
    return rep.data
  })
}

const GetGroupTypeList = async () => {
  return await apiGateway.get<GroupType[]>('/api/v1/groups/meta').then((rep) => {
    return rep.data
  })
}

const GetUserFormRights = async (userId: string, cat?: string) => {
  return await apiGateway
    .get<UserInfo>(`/api/v1/users/${userId}/form-rights?form_cat=${cat || ''}`)
    .then((rep) => {
      return rep.data
    })
}
const GetOrganizationMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/organizations/meta').then((rep) => {
    return rep.data
  })
}

const GetLimitList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/organizations/limitedList').then((rep) => {
    return rep.data
  })
}

const GetAllRoleList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/role/getAllRoleList').then((rep) => {
    return rep.data
  })
}

const oktaReg = async (params: any) => { 
  return await axios.post<UserInfo>('/api/v1/users', params, {
    headers: {
      'Authorization': `SSWS ${apiToken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((rep) => {
    return rep.data;
  });
}

const oktaAuthorization = async (appId: string, params: any) => {
  const url = `/api/v1/apps/${appId}/users`;
  
  return await axios.post<UserInfo>(url, params, {
    headers: {
      'Authorization': `SSWS ${apiToken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((rep) => {
    return rep.data;
  })
}

// const oktaDeleteUser = async (userId: string, appId: string) => {
// 
//   const url = `/api/v1/apps/${appId}/users/${userId}`;
//   return await axios.delete(url, {
//     headers: {
//       'Authorization': `SSWS ${apiToken}`
//     }
//   }).then((rep) => {
//     return rep.data;
//   })
// }

const deleteUser = async (userId: string) => {
  const url = `/api/v1/users/${userId}`;

  return await axios.delete(url, {
    headers: {
      'Authorization': `SSWS ${apiToken}`
    }
  }).then((rep) => {
    return rep.data;
  })
}

const assignUserToApp = async (appId: string, userId: string) => {
  const url = `/api/v1/apps/${appId}/users/${userId}`;

  return await axios.put(url, {}, {
    headers: {
      'Authorization': `SSWS ${apiToken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((rep) => {
    return rep.data;
  });
}

const addUserToGroup = async (groupId: string, userId: string) => {
  const url = `/api/v1/groups/${groupId}/users/${userId}`;

  return await axios.put(url, {}, {
    headers: {
      'Authorization': `SSWS ${apiToken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((rep) => {
    return rep.data;
  });
}

const UserService = {
  GetUserInfo,
  GetRoleMetaList,
  GetUserMetaList,
  GetOrganizationMetaList,
  GetGroupTypeList,
  GetUserFormRights,
  RegisterUser,
  GetLimitList,
  GetAllRoleList,
  CreateUser,
  oktaReg,
  oktaAuthorization,
  assignUserToApp,
  addUserToGroup,
  deleteUser
}

export default UserService
