import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import {
  ProjectFormModel,
  InitProjectForm,
  ProjectTypeEnum,
  EnrolmentStatusEnum,
  OrganizationTypeEnum,
} from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { useNavigate, useParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import FormBinarySelect from '@components/form/binarySelect'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import styles from '@styles/baseButton.module.scss'
import getErrMsg from '@utils/getErrMsg'
import {
  Box,
  Typography,
  Divider,
  Button,
  Grid,
  useMediaQuery,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import CustomDialog from '@components/CustomDialog'
import DateTimePicker from '@components/form/dateTimePicker'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import FormCheckbox from '@components/form/checkbox'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import FormDataGrid from '@components/form/datagrid'
import Tabs from '@components/Tabs'
import BaseButton from '@components/button/BaseButton'
import FormMultipleSelect from '@components/form/multipleSelect'
import { ApiStatusEnum } from '@services/model/base.model'
import _ from 'lodash'
import cleanString from '@utils/cleanString'
// import iBeamData from './ibeam.json'
import { UserRoles } from '@models/User'
import FormDateTimePicker from '@components/form/dateTimePicker'
import { StringMap } from 'i18next'

export type DialogContextProps = {
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

export default function ProjectForm() {
  const UUID = uuid()

  const [readonly, setReadonly] = useState<boolean>(false)
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  let { id: formId } = useParams<string>()
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<
    ProjectFormModel & {
      ibeamData?: {
        uuid: string
        apiRequestLog: {
          id: number
          requestTime: string
          requestingAcc: string
          apiType: string
          responseCode: number
          downloadSize: number
        }[]
      }
    }
  >({
    defaultValues: { ...InitProjectForm },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const iBeamData = watch('ibeamData') || { uuid: '', apiRequestLog: [] }
  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setRequestSubmit } = useAPIFetch()
  const { setRequest: setRequestUsers } = useAPIFetch()

  const { setRequest: setIbeamRequest } = useAPIFetch()
  const [userDetailList, setUserDetailList] = useState<any>([])
  const rows: any = []
  const [usersData, setUsersData] = useState<any>(rows)
  const [activeUsersData, setActiveUsersData] = useState<any>(rows)
  const [inactiveUsersData, setInactiveUsersData] = useState<any>(rows)
  const [pendingInvitationUsersData, setPendingInvitationUsersData] = useState<any>(rows)
  const [pendingApprovalUsersData, setPendingApprovalUsersData] = useState<any>(rows)
  const reload = async () => {
    console.debug('[globalState]', globalState)

    setRequest({
      callback: async () => {
        if (formId) {
          await AdminService.GetProjectForm(formId)
            .then(async (res) => {
              if (res) {
                reset(res)
                setReadonly(res.projectPermission?.canUpdate === false)
              }
            })
            .catch((err) => {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
            })
        }
        isMounted.current = true
      },
    })
    setRequestUsers({
      callback: async () => {
        if (formId) {
          await AdminService.GetProjectUserList(formId)
            .then((userList) => {
              if (userList && userDetailList) {
                const users = userDetailList.filter((userDetail) =>
                  userList.includes(userDetail.userId),
                )

                setUsersData(users)

                setActiveUsersData(users)
                setInactiveUsersData(
                  users.filter(
                    (x) =>
                      x.enrolment ===
                        globalState?.enrolmentStatusList?.find(
                          (enrolStatus) =>
                            cleanString(enrolStatus.value) ===
                            cleanString(EnrolmentStatusEnum.disabled)?.key,
                        ) || x.status === false,
                  ),
                )
                setPendingInvitationUsersData(
                  users.filter(
                    (x) =>
                      x.enrolment ===
                      globalState?.enrolmentStatusList?.find(
                        (enrolStatus) =>
                          cleanString(enrolStatus.value) ===
                          cleanString(EnrolmentStatusEnum.pendingInvitation)?.key,
                      ),
                  ),
                )
                setPendingApprovalUsersData(
                  users.filter(
                    (x) =>
                      x.enrolment ===
                      globalState?.enrolmentStatusList?.find(
                        (enrolStatus) =>
                          cleanString(enrolStatus.value) ===
                          cleanString(EnrolmentStatusEnum.pendingApproval)?.key,
                      ),
                  ),
                )
              }
            })
            .catch((err) => {
              enqueueSnackbar(getErrMsg(t, err), {
                variant: 'error',
              })
            })
        }
      },
    })
    setIbeamRequest({
      callback: async () => {
        if (formId) {
          await AdminService.GetIbeamData(formId).then(async (res) => {
            if (res) {
              setValue('ibeamData', res)
            }
          })
        }
      },
    })
  }
  // const hasRightAssignOrganization =
  //   userInfo?.allRights === true &&
  //   cleanString(userInfo?.organization?.organizationType?.name) ===
  //     cleanString(OrganizationTypeEnum.internal)

  const hasRightAssignOrganization = userInfo?.allRights === true

  useEffect(() => {
    if (formId == null) {
      reset({ ...InitProjectForm })
    }
    reloadUserDetailList()
  }, [formId])

  useEffect(() => {
    if (!hasRightAssignOrganization) {
      setValue('organizationId', userInfo?.organization?.uuid)
    }
  }, [])

  const reloadUserDetailList = async () => {
    let res = await AdminService.GetUserListProject({ skipPagination: true }, null, false)
    console.debug('[userDetailList]', res?.list)
    if (res?.list) {
      setUserDetailList(res.list)
    }

    isMounted.current = true
  }
  useEffect(() => {
    reload()
  }, [userDetailList])

  const handleOnSubmit = async (event: any) => {
    let values = getValues()

    if (values.status === undefined) {
      values.status = true
    }
    setRequestSubmit({
      callback: async () => {
        const chitAccountNo = values.chitAccountNo ?? '';
        const chitAccountNoExists = await AdminService.CheckChitAccountNoExists(chitAccountNo);
        console.log('chitAccountNoExists', chitAccountNoExists);
        if (chitAccountNoExists) {
          const userConfirmed = window.confirm(t('Chit Account number already exists. Do you want to proceed?'));
          if (!userConfirmed) {
            return;
          }
        }else{
          const userConfirmed = window.confirm(t('Confirm to proceed?'));
          if (!userConfirmed) {
            return;
          }
        }
        
        await AdminService.SaveCreateProject(values).then((resp) => {
          enqueueSnackbar(t('common:messages.recordSaved'), {
            variant: 'success',
            action: (key) => {
              return <SnackbarCloseButton id={key} />
            },
          })
          NavigateTo(navigate, '/admin/project/all-records')
        })
      },
    })
  }

  const handleAddProjectUser = async (event: any) => {
    const { addUserId } = getValues()
    const req = { users: [addUserId] } // api accept array
    if (formId) {
      await AdminService.AddProjectUser(formId, req)
        .then((res) => {
          if (res) {
            reload()
          }
        })
        .catch((err) => {
          enqueueSnackbar(getErrMsg(t, err), {
            variant: 'error',
          })
        })
    }
  }

  const handleRemoveProjectUser = async (userId: any) => {
    const req = { users: [userId] } // api accept array
    if (formId) {
      const res = await AdminService.RemoveProjectUser(formId, req)
        .then((res) => {
          if (res?.status === ApiStatusEnum.SUCCESS) {
            reload()
          }
        })
        .catch((err) => {
          enqueueSnackbar(getErrMsg(t, err), {
            variant: 'error',
          })
        })
    }
  }
  let index = 0
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'block',
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  }))

  const StyledDivider = styled(Divider)(() => ({
    '&::before, &::after': {
      borderColor: 'black',
    },
  }))

  const ButtonGroupBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    pt: 2,
  }))

  const SwitcherButton = styled(Button)(() => ({
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  }))

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/admin/project/all-records')
  }

  function findTreeNode(key: number, tree: any[]): any {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.key === key) {
        return node
      } else if (node.children) {
        const childNode = findTreeNode(key, node.children)
        if (childNode) {
          return childNode
        }
      }
    }
    return null
  }

  function getRoleName(key: number, treeData): string {
    const node = findTreeNode(key, treeData)
    return node ? node.value : ''
  }

  function ArrayRenderer(params: GridCellParams) {
    const arrayValue = params.value as number[]
    const roleNames = arrayValue.map((value) => `${getRoleName(value, globalState.roleMetaList)}`)
    const roleNameString = roleNames.join(', ')
    return <div>{roleNameString}</div>
  }

  const RoleDropdownOperator: GridFilterOperator = {
    label: 'Role Name',
    value: 'role-dropdown',
    getApplyFilterFn: (filterItem) => {
      const roleId = filterItem.value as number

      return (params) => {
        const roleIds = params.value as number[]
        return roleId === 0 || !roleId || roleIds.includes(roleId)
      }
    },
    InputComponent: ({ item, applyValue, ...rest }) => {
      const [selectedRoleId, setSelectedRoleId] = useState<number>(0)

      const handleRoleSelect = (event) => {
        const roleId = event.target.value
        setSelectedRoleId(roleId)
        applyValue({ ...item, value: roleId })
      }

      return (
        <FormControl sx={{ minWidth: 120 }} variant={'standard'}>
          <InputLabel id="role-select-label">Role Name</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={selectedRoleId}
            onChange={handleRoleSelect}
            {...rest}>
            <MenuItem value={0}>{t('Select')}</MenuItem>
            {globalState.roleMetaList?.map((node) => (
              <MenuItem key={node.key} value={node.key}>
                {t(node.value as string)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
  }

  const iBeamColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('id'),
      width: 50,
      editable: false,
    },
    {
      field: 'requestTime',
      headerName: t('Requesting Time'),
      width: 200,
      editable: false,
      renderCell: (params) => {
        console.debug('params', params)
        return <FormDateTimePicker uneditable value={params.formattedValue} onChange={() => {}} />
      },
    },
    {
      field: 'requestingAcc',
      headerName: t('Requesting Account'),
      width: 200,
      editable: false,
    },
    {
      field: 'apiType',
      headerName: t('API Type'),
      width: 200,
      editable: false,
    },
    {
      field: 'responseCode',
      headerName: t('Response Code'),
      width: 150,
      editable: false,
    },
    {
      field: 'downloadSize',
      headerName: t('Download Size (mb)'),
      width: 150,
      editable: false,
      renderCell: (params) => {
        console.debug('params', params)
        return params.formattedValue && typeof params.formattedValue === 'number'
          ? (params.formattedValue / 1048576).toFixed(2)
          : 'unknown size'
      },
    },
  ]

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: t('Display Name'),
      width: 200,
      editable: false,
    },
    {
      field: 'roleId',
      headerName: t('Roles'),
      width: 110,
      editable: false,
      renderCell: ArrayRenderer,
      filterOperators: [RoleDropdownOperator],
    },
    {
      field: 'email',
      headerName: t('Email'),
      width: 250,
      editable: false,
    },
    {
      field: 'userId',
      headerName: t('Link to Edit User'),

      renderCell: (params: GridRenderCellParams) => (
        <Button
          onClick={() => {
            navigate(`/admin/user/${params.value}`)
          }}
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}>
          {t('Link to User')}
        </Button>
      ),
      width: 150,
    },
    {
      field: 'removeUser',
      headerName: t('Remove User'),
      renderCell: (params: GridRenderCellParams) => (
        <Button
          onClick={() => {
            handleRemoveProjectUser(params.row.userId)
          }}
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}>
          {t('Remove user from project')}
        </Button>
      ),
      width: 300,
    },
  ]

  type ListComponentProps = {
    status: 'active' | 'inactive' | 'invited'
    dataType: 'User' | 'Project' | 'Organization'
    control: any
    readonly: boolean
    data: any[]
    columns: GridColDef[]
    rowId: string
  }
  const ListComponent = (props: ListComponentProps) => {
    const { status, control, readonly, data, dataType, columns, rowId } = props

    const controlProps = {
      name: `${status}${dataType}List`,
      control,
    }

    return (
      <FormController controllerProps={controlProps}>
        <FormDataGrid
          getRowId={(row: any) => row[rowId]}
          rows={data}
          columns={columns}
          // disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          uneditable={readonly}
          checkboxSelection={false}
        />
      </FormController>
    )
  }
  const activeUserList = (
    <ListComponent
      status="active"
      dataType="User"
      control={control}
      readonly={readonly}
      data={activeUsersData}
      columns={columns}
      rowId={'userId'}
    />
  )

  const inactiveUserList = (
    <ListComponent
      status="inactive"
      dataType="User"
      control={control}
      readonly={readonly}
      data={inactiveUsersData}
      columns={columns}
      rowId={'userId'}
    />
  )

  const pendingInvitationUserList = (
    <ListComponent
      status="invited"
      dataType="User"
      control={control}
      readonly={readonly}
      data={pendingInvitationUsersData}
      columns={columns}
      rowId={'userId'}
    />
  )
  const pendingApprovalUserList = (
    <ListComponent
      status="invited"
      dataType="User"
      control={control}
      readonly={readonly}
      data={pendingApprovalUsersData}
      columns={columns}
      rowId={'userId'}
    />
  )

  const [projectTypeId, contractId, organizationId] = useWatch({
    name: ['projectTypeId', 'contractId', 'organizationId'],
    control: control,
  })

  const isSuperAdmin = userInfo.allRights

  const contractIdList = globalState.contractList
    ?.filter((c) => c.contractPermission?.canCreateProject === true || isSuperAdmin === true)
    .map((contract) => ({
      key: contract.id,
      value: contract.contractName,
    }))

  const allContractIdList = globalState.contractList?.map((contract) => ({
    key: contract.id,
    value: contract.contractName,
  }))
  const contractNoList = globalState.contractList?.map((contract) => ({
    key: contract.id,
    value: contract.contractNo,
  }))

  const getRoleIdByRoleName = (roleName: string) => {
    const role = globalState.roleMetaList?.find((role) => {
      return role.value === roleName
    })

    if (role) {
      return role.key
    } else {
      return null // or any other value that represents an absence of roleId
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h4'}
              sx={{ fontWeight: 'bold', margin: theme.spacing(5) }}>
              {!formId ? t('Add New Project') : t('Edit Project')}
            </Typography>
          </Box>
          <Typography
            variant={matchDownMd ? 'h6' : 'h5'}
            sx={{
              fontWeight: 'bold',
              marginBottom: theme.spacing(4),
              marginLeft: theme.spacing(6),
              marginRight: theme.spacing(6),
            }}>
            {t('Part A / Project Information')}
          </Typography>
          <FormContainer fkey={`${UUID}-ei-a`}>
            {formId ? (
              <>
                <FormField fieldName="Contract name" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'contractName',
                      control,
                    }}>
                    <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={true} />
                  </FormController>
                </FormField>
                <FormField fieldName="Contract number" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'contractNo',
                      control,
                    }}>
                    <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={true} />
                  </FormController>
                </FormField>
              </>
            ) : (
              <>
                <FormField fieldName="Contract name" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'contractId',
                      control,
                    }}>
                    <FormSelect
                      options={
                        !!formId || readonly ? allContractIdList || [] : contractIdList || []
                      }
                      uneditable={!!formId || readonly}
                    />
                  </FormController>
                </FormField>
                <FormField fieldName="Contract number" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'contractId',
                      control,
                    }}>
                    <FormSelect options={contractNoList || []} uneditable={true} />
                  </FormController>
                </FormField>
              </>
            )}

            <FormField fieldName="Contract phase" index={index++}>
              <FormController
                controllerProps={{
                  name: 'contractPhase',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            {!!formId && (
              <FormField fieldName="Assigned contract" index={index++}>
                <>
                  {contractId && (
                    <Box
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        NavigateTo(navigate, '/admin/contract/:id', { id: contractId })
                      }}>
                      {t('Link to Assigned Contract')}
                    </Box>
                  )}
                </>
              </FormField>
            )}
            <FormField fieldName="Project name" index={index++}>
              <FormController
                controllerProps={{
                  name: 'projectName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Description of project works as part of contract" index={index++}>
              <FormController
                controllerProps={{
                  name: 'projectDescription',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true, multiline: true, rows: 3 }}
                  uneditable={readonly}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Assigned organization name" index={index++}>
              {hasRightAssignOrganization ? (
                <FormController
                  controllerProps={{
                    name: 'organizationId',
                    control,
                  }}>
                  <FormSelect
                    options={
                      globalState.organizationMetaList.map((x) => {
                        return {
                          key: x.identifier,
                          value: x.companyName,
                        }
                      }) || []
                    }
                    uneditable={!!formId || readonly}
                  />
                </FormController>
              ) : (
                <FormController
                  controllerProps={{
                    name: 'organizationId',
                    control,
                  }}>
                  <FormSelect
                    options={
                      globalState.organizationMetaList?.map((x) => {
                        return {
                          key: x.identifier,
                          value: x.companyName,
                        }
                      }) || []
                    }
                    uneditable={true}
                  />
                </FormController>
              )}
            </FormField>
            <FormField fieldName="Assigned Users" index={index++}>
              <Box sx={{ height: 450, width: '100%' }}>{activeUserList}</Box>
            </FormField>
            <FormField fieldName="Project site name" index={index++}>
              <FormController
                controllerProps={{
                  name: 'projectSiteName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Project address" index={index++}>
              <FormController
                controllerProps={{
                  name: 'projectAddress',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true, multiline: true, rows: 3 }}
                  uneditable={readonly}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Project location" index={index++}>
              <FormController
                controllerProps={{
                  name: 'projectLocationId',
                  control,
                }}>
                <FormSelect
                  key={2}
                  options={globalState?.projectLocationList || []}
                  uneditable={readonly}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Project district" index={index++}>
              <FormController
                controllerProps={{
                  name: 'projectDistrictId',
                  control,
                }}>
                <FormSelect
                  options={globalState?.projectDistrictList || []}
                  uneditable={readonly}
                />
              </FormController>
            </FormField>
            <FormField fieldName="CHIT account number" index={index++}>
              <FormController
                controllerProps={{
                  name: 'chitAccountNo',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="iBEAM project number" index={index++}>
              <FormController
                controllerProps={{
                  name: 'ibeamProjectNo',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Construction start (date)" index={index++}>
              <FormController
                controllerProps={{
                  name: 'constructionStartDate',
                  control,
                }}>
                <DateTimePicker
                  type={'date'}
                  sx={{ width: '100%' }}
                  // variant="outlined"
                  uneditable={readonly}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Expected construction end (date)" index={index++}>
              <FormController
                controllerProps={{
                  name: 'expectedConstructionDate',
                  control,
                }}>
                <DateTimePicker
                  type={'date'}
                  sx={{ width: '100%' }}
                  // variant="outlined"
                  uneditable={readonly}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Contract value (million HKD)" index={index++}>
              <FormController
                controllerProps={{
                  name: 'contractValue',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  uneditable={readonly}
                  inputType={'float'}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Construction Stage(s) Involved" index={index++}>
              <Stack>
                <FormController
                  controllerProps={{
                    name: 'isSiteFormation',
                    control,
                  }}>
                  <FormCheckbox label={'Site Formation'} uneditable={readonly} />
                </FormController>
                <FormController
                  controllerProps={{
                    name: 'isDemolition',
                    control,
                  }}>
                  <FormCheckbox label={'Demolition'} uneditable={readonly} />
                </FormController>
                <FormController
                  controllerProps={{
                    name: 'isFoundation',
                    control,
                  }}>
                  <FormCheckbox label={'Foundation & sub-structure'} uneditable={readonly} />
                </FormController>
                <FormController
                  controllerProps={{
                    name: 'isSuperstruct',
                    control,
                  }}>
                  <FormCheckbox label={'Superstructure'} uneditable={readonly} />
                </FormController>
                <FormController
                  controllerProps={{
                    name: 'isOthers',
                    control,
                  }}>
                  <FormCheckbox label={'Other'} uneditable={readonly} />
                </FormController>
              </Stack>
            </FormField>
            <FormField fieldName="Project type" index={index++}>
              <FormController
                controllerProps={{
                  name: 'projectTypeId',
                  control,
                }}>
                <FormSelect options={globalState?.projectTypeList || []} uneditable={readonly} />
              </FormController>
            </FormField>
            {projectTypeId ===
              globalState?.projectTypeList?.find((projType) => {
                return cleanString(projType.value) === cleanString(ProjectTypeEnum.building)
              })?.key && (
              <>
                <FormField fieldName="Project classification" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'projectClassificationB1Id',
                      control,
                    }}>
                    <FormSelect
                      key={2}
                      options={globalState?.projectClassificationB1List || []}
                      uneditable={readonly}
                    />
                  </FormController>
                </FormField>
                <FormField fieldName="Construction Floor Area (sqm)" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'constructionFloorArea',
                      control,
                    }}>
                    <FormFreeText
                      textfieldProps={{ fullWidth: true }}
                      uneditable={readonly}
                      inputType={'float'}
                    />
                  </FormController>
                </FormField>
                <FormField fieldName="Gross Floor Area (sqm)" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'grossFloorArea',
                      control,
                    }}>
                    <FormFreeText
                      textfieldProps={{ fullWidth: true }}
                      uneditable={readonly}
                      inputType={'float'}
                    />
                  </FormController>
                </FormField>
              </>
            )}

            {projectTypeId ===
              globalState?.projectTypeList?.find((projType) => {
                return projType.value === 'Civil Engineering'
              })?.key && (
              <>
                <FormField fieldName="Project classification" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'projectClassificationB2Id',
                      control,
                    }}>
                    <FormSelect
                      key={2}
                      options={globalState?.projectClassificationB2List || []}
                      uneditable={readonly}
                    />
                  </FormController>
                </FormField>
                <FormField fieldName="Area (sqm)" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'civilArea',
                      control,
                    }}>
                    <FormFreeText
                      textfieldProps={{ fullWidth: true }}
                      uneditable={readonly}
                      inputType={'float'}
                    />
                  </FormController>
                </FormField>
              </>
            )}
          </FormContainer>

          <FormContainer fkey={`${UUID}-admin-project-status`}>
            {formId ? (
              <>
                <Typography
                  variant={matchDownMd ? 'h6' : 'h5'}
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: theme.spacing(4),
                  }}>
                  {t('Project Status')}
                </Typography>
                <FormField fieldName="Change Project Status" index={index++}>
                  <FormController
                    controllerProps={{
                      name: 'status',
                      control,
                    }}>
                    <FormBinarySelect
                      binaryOptionNames={{ yes: t('Enabled'), no: t('Disabled') }}
                      uneditable={readonly}
                    />
                  </FormController>
                </FormField>
              </>
            ) : (
              <></>
            )}
          </FormContainer>
          <Box sx={{ minHeight: '500px' }}>
            <FormContainer fkey={`${UUID}-admin-organ-c`}>
              <Typography
                variant={matchDownMd ? 'h6' : 'h5'}
                sx={{
                  fontWeight: 'bold',
                  marginBottom: theme.spacing(4),
                }}>
                {t('Add User to Project')}
              </Typography>

              <FormField fieldName="Add User" index={index++}>
                <Box sx={{ width: '100%' }}>
                  <Stack direction="row" spacing={1}>
                    <FormController
                      controllerProps={{
                        name: 'addUserId',
                        control,
                      }}>
                      <FormSelect
                        options={
                          userDetailList
                            ?.filter(
                              (x) =>
                                x.status === true && 
                                ((x.organizationId === organizationId || 
                                x.organization2Id === organizationId)
                                && (
                                  x.roleId?.includes(getRoleIdByRoleName(UserRoles.envOfficer)) || 
                                  x.roleId?.includes(getRoleIdByRoleName(UserRoles.foreman)) || 
                                  x.roleId?.includes(getRoleIdByRoleName(UserRoles.envManager))
                                )||x.roleId?.includes(getRoleIdByRoleName(UserRoles.clerkOfWork)))
                            )
                            ?.filter((y) => !usersData.some((user) => user.userId === y.userId)) // filter out users already in project
                            .map((u) => {
                              return { key: u.userId, value: u.displayName }
                            }) || []
                        }
                      />
                    </FormController>

                    <BaseButton
                      displayText={t('Add User to Project')}
                      disabled={false}
                      className={styles.formButton}
                      sx={{ width: '200px', marginLeft: '10px' }}
                      onClickHandler={handleAddProjectUser}
                    />
                  </Stack>
                </Box>
              </FormField>
            </FormContainer>
            <FormContainer fkey={`${UUID}-admin-api-log`}>
              <Typography
                variant={matchDownMd ? 'h6' : 'h5'}
                sx={{
                  fontWeight: 'bold',
                  marginBottom: theme.spacing(4),
                }}>
                {t('iBEAM API Information')}
              </Typography>
              <FormField fieldName="Project UUID" index={index++}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  uneditable={true}
                  inputType={'float'}
                  onChange={() => {}}
                  value={iBeamData?.uuid}
                />
              </FormField>
              <FormField fieldName="Api Request Log" index={index++}>
                <FormDataGrid
                  // getRowId={(row: any) => row[rowId]}
                  rows={iBeamData?.apiRequestLog || []}
                  columns={iBeamColumns}
                  // disableSelectionOnClick
                  // experimentalFeatures={{ newEditingApi: true }}
                  uneditable={true}
                  checkboxSelection={false}
                />
              </FormField>
            </FormContainer>
          </Box>
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!formId ? t('Back') : t('Back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleOnSubmit}
                disabled={false}>
                {!formId ? t('Submit') : t('Save')}
              </SwitcherButton>
            </ButtonGroupBox>
          </Grid>
          <DevTool control={control} />
        </StyledBox>
      </Box>
    </Fragment>
  )
}
