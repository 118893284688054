import { InitUserInfo } from '@services/model/user.model'
import axios from 'axios'
import uuid from 'react-uuid'

const https = require('https-browserify')

const baseUrl = process.env.REACT_APP_API_BASE as string

const apiGateway = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

apiGateway.interceptors.request.use(
  function (config) {
    const ciamBase =
      (process.env.REACT_APP_API_CIAM_BASE as string) || 'https://cic-onepass.okta.com'
    let isCiam = false
    if (config?.url?.includes(ciamBase)) {
      isCiam = true
    }

    // !TODO Only for dev, rewrite the base url to particular service endpoint

    if (process.env.REACT_APP_ENV === 'DEV' && config.url) {
      if (isCiam) {
        config.baseURL = process.env.REACT_APP_API_CIAM_BASE as string
      } else if (
        config.url.includes('/users') ||
        config.url.includes('/organizations') ||
        config.url.includes('/group') ||
        config.url.includes('/role') ||
        config.url.includes('/project/getProjectByOrganizationId')
      ) {
        config.baseURL = process.env.REACT_APP_API_USER_BASE as string
      } else if (config.url.includes('/files')) {
        config.baseURL = process.env.REACT_APP_API_FILE_BASE as string
      } else if (config.url.includes('/notifications')) {
        config.baseURL = process.env.REACT_APP_API_NOTIFICATION_BASE as string
      } else if (config.url.includes('airflow')) {
        config.baseURL = process.env.REACT_APP_AIRFLOW_BASE as string
      } else if (config.url.includes('/convertors')) {
        config.baseURL = process.env.REACT_APP_API_FILE_UPLOAD_BASE as string
      } else {
        config.baseURL = process.env.REACT_APP_API_FORM_BASE as string
      }
    }

    // add jwt
    const item = window.localStorage.getItem('usr')

    const ciamToken: any = window.localStorage.getItem('okta-token-storage')
    let accessToken: any = null
    let idToken: any = null
    let apiKey: any = process.env.REACT_APP_API_KEY || '00WsOzL1UImiQdh0MFRLtfDn7WBQve7DZoOblL26DK'
    if (ciamToken) {
      accessToken = JSON.parse(ciamToken)?.accessToken?.accessToken
      idToken = JSON.parse(ciamToken)?.idToken?.idToken
    }

    if (item) {
      let userInfo = JSON.parse(item)
      const token = userInfo.token
      if (config.url?.includes('airflow')) {
        config.headers = {
          ...config.headers,
          Authorization: 'Basic Y2ljX2FwaV91c2VyOlBAc3N3MHJk',
        }
      } else if (token || (isCiam && (accessToken || idToken || apiKey))) {
        config.headers = {
          ...config.headers,
          Authorization: isCiam ? 'SSWS ' + apiKey : 'Bearer ' + token,
        }
      }

      if (userInfo.uuid) {
        config.headers = {
          ...config.headers,
          'X-Caller-Id': userInfo.uuid,
        }
      }
    }
    let contract = '{}'
    let project = '{}'

    contract = window.localStorage.getItem('currentContract') || '{}'
    let currentContract = JSON.parse(contract)

    project = window.localStorage.getItem('currentProject') || '{}'
    let currentProject = JSON.parse(project)

    if (currentProject && currentProject.id) {
      config.headers = {
        ...config.headers,
        'X-Project-Id': currentProject.id,
      }
    }

    if (currentContract && currentContract.id) {
      config.headers = {
        ...config.headers,
        'X-Contract-Id': currentContract.id,
      }
    }

    // !TODO add trace-id, Check later about E2E Tracing
    config.headers = {
      ...config.headers,
      'X-Trace-Id': uuid(),
    }

    return config
  },
  function (error) {
      // Do something with request error
    return Promise.reject(error)
  },
)

apiGateway.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    if (axios.isCancel(err)) return Promise.reject(err)

    const originalConfig = err.config
    if (
      originalConfig?.url !== '/Auth/SignIn' &&
      !originalConfig?.url.includes('RefreshToken') &&
      err.response
    ) {
      if (err.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const item = window.localStorage.getItem('usr')
          if (item) {
            let userInfo = JSON.parse(item)
            const rs = await apiGateway.get(`/Auth/RefreshToken?rt=${userInfo.refreshToken}`)

            const { token, refreshToken, accessRights, allRights } = rs.data
            userInfo.token = token
            userInfo.refreshToken = refreshToken

            userInfo.accessRights = accessRights
            userInfo.allRights = allRights
            userInfo.lastActionDt = new Date()

            localStorage.setItem('usr', JSON.stringify(userInfo))
          }

          return apiGateway(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
      // else if (err.response.status === 403) {
      //     window.location.href = '/401'
      //     return;
      // }
    } else if (originalConfig?.url.includes('RefreshToken') && err.response) {
      if (err.response.status === 401) {
        localStorage.setItem('usr', JSON.stringify(InitUserInfo))
        window.location.href = '/login'
        return
      }
    }

    return Promise.reject(err)
  },
)

export default apiGateway
