import apiGateway from '@utils/axiosInterceptor'
import {
  UserListModel,
  UserFormModel,
  ProjectFormModel,
  SubmitFormModel,
  DataSubmissionResponse,
  OrganizationFormModel,
  ContractFormModel,
  inviteUserModel,
} from './model/admin.model'
import { AxiosRequestConfig } from 'axios'

const GetUserList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: UserListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/users/list`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      let { list, lastModifyList, totalCount } = rep.data
      return rep.data
    })
}

const GetUserListProject = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: UserListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/users/list`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      let { list, lastModifyList, totalCount } = rep.data
      return rep.data
    })
}

const GetProjectList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: UserListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/projects/list`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      let { list, lastModifyList, totalCount } = rep.data
      return rep.data
    })
}

//TODO : change to real api
const GetOrganizationList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: UserListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/organizations/list`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      let { list, lastModifyList, totalCount } = rep.data
      return rep.data
    })
}

const GetContractList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: UserListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/contracts/list`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      let { list, lastModifyList, totalCount } = rep.data
      return rep.data
    })
}

const GetUserForm = async (userId: string) => {
  return await apiGateway.get<UserFormModel>(`api/v1/users?uuid=${userId}`).then((rep) => {
    return rep.data
  })
}

const SubmitEdpUrl = async (req: string) => {
  return await apiGateway
    .post<DataSubmissionResponse>(`airflow/api/v1/dags/epd_download/dagRuns`, {
      conf: { download_link: req },
    })
    .then((rep) => {
      return rep.data
    })
}

const SubmitWeightUrl = async (req: string) => {
  return await apiGateway
    .post<DataSubmissionResponse>(`airflow/api/v1/dags/permitted_weight_download/dagRuns`, {
      conf: { download_link: req },
    })
    .then((rep) => {
      return rep.data
    })
}

const GetProjectForm = async (formId: string) => {
  return await apiGateway.get<ProjectFormModel>(`api/v1/projects/${formId}`).then((rep) => {
    return rep.data
  })
}
const GetContractForm = async (formId: string) => {
  return await apiGateway.get<ContractFormModel>(`api/v1/contracts/${formId}`).then((rep) => {
    return rep.data
  })
}
const GetOrganizationForm = async (formId: string) => {
  return await apiGateway
    .get<OrganizationFormModel>(`api/v1/organizations?uuid=${formId}`)
    .then((rep) => {
      return rep.data
    })
}
const GetOrganizationMeta = async () => {
  return await apiGateway.get(`api/v1/organizations/meta`).then((rep) => {
    return rep.data
  })
}

const GetProjectOptionList = async () => {
  return await apiGateway.get(`api/v1/contracts/options`).then((rep) => {
    return rep.data
  })
}

const CreateUser = async (req: UserFormModel) => {}

const SaveCreateUser = async (req: UserFormModel) => {
  if (req.uuid === undefined) {
    return await apiGateway.post(`api/v1/users/`, req).then((rep) => {
      return rep.data
    })
  } else {
    return await apiGateway.put(`api/v1/users/${req.uuid}`, req).then((rep) => {
      return rep.data
    })
  }
}

const SaveCreateProject = async (req: ProjectFormModel) => {
  if (!req.id && req.id !== 0) {
    return await apiGateway.post(`api/v1/projects/`, req).then((rep) => {
      return rep.data
    })
  } else {
    return await apiGateway.put(`api/v1/projects/${req.id}`, req).then((rep) => {
      return rep.data
    })
  }
}

const SaveCreateContract = async (req: ContractFormModel) => {
  if (!req.id && req.id !== 0) {
    return await apiGateway.post(`api/v1/contracts/`, req).then((rep) => {
      return rep.data
    })
  } else {
    return await apiGateway.put(`api/v1/contracts/${req.id}`, req).then((rep) => {
      return rep.data
    })
  }
}

const CheckContractNoExists = async (contractNo: string) => {
  if (contractNo && contractNo !== "") {
    return await apiGateway.get(`api/v1/contracts/exists/${contractNo}`).then((rep) => {
      return rep.data.exist
    })
  }
  return false
}

const CheckChitAccountNoExists = async (chitAccountNo: string) => {
  if (chitAccountNo && chitAccountNo !== "") {
    return await apiGateway.get(`api/v1/projects/exists/${chitAccountNo}`).then((rep) => {
      return rep.data.exist
    })
  } 
  return false
}

const SaveCreateOrganization = async (req: OrganizationFormModel) => {
  if (!req.uuid) {
    return await apiGateway.post(`api/v1/organizations/`, req).then((rep) => {
      return rep.data
    })
  } else {
    return await apiGateway.put(`api/v1/organizations/${req.uuid}`, req).then((rep) => {
      return rep.data
    })
  }
}

const resetUserPassword = async (email: string) => {
  return await apiGateway.get(`api/v1/users/reset_password?email=${email}`).then((rep) => {
    return rep.data
  })
}

const InviteUser = async (req: inviteUserModel) => {
  return await apiGateway.post(`api/v1/users/invite`, req).then((rep) => {
    return rep.data
  })
}

const GetContractUserList = async (contractId: string) => {
  return await apiGateway.get(`api/v1/contracts/${contractId}/user/list`).then((rep) => {
    return rep.data
  })
}

const GetProjectUserList = async (projectId: string) => {
  return await apiGateway.get(`api/v1/projects/${projectId}/user/list`).then((rep) => {
    return rep.data
  })
}

const AddProjectUser = async (projectId: string, req: any) => {
  return await apiGateway.put(`api/v1/projects/${projectId}/user/add`, req).then((rep) => {
    return rep.data
  })
}

const AddContractUser = async (contractId: string, req: any) => {
  return await apiGateway.put(`api/v1/contracts/${contractId}/user/add`, req).then((rep) => {
    return rep.data
  })
}

const RemoveProjectUser = async (projectId: string, req: any) => {
  return await apiGateway.put(`api/v1/projects/${projectId}/user/remove`, req).then((rep) => {
    return rep
  })
}

const RemoveContractUser = async (contractId: string, req: any) => {
  return await apiGateway.put(`api/v1/contracts/${contractId}/user/remove`, req).then((rep) => {
    return rep
  })
}

const GetContractListByUserId = async (userId: string) => {
  return await apiGateway.get(`api/v1/contracts?user_id=${userId}`).then((rep) => {
    return rep.data
  })
}

const GetProjectListByUserId = async (userId: string) => {
  return await apiGateway.get(`api/v1/projects?user_id=${userId}`).then((rep) => {
    return rep.data
  })
}

const ciamBase = (process.env.REACT_APP_API_CIAM_BASE as string) || 'https://cic-onepass.okta.com'

const CiamGetUserProfile = async (email: string) => {
  const encodedEmail = encodeURIComponent(email)
  return await apiGateway.get(`${ciamBase}/api/v1/users/${encodedEmail}`).then((rep) => {
    console.debug('[CiamGetUserProfile]', rep)
    return rep.data
  })
}
const CiamCreateNewUser = async (req: any) => {
  const groupId = process.env.REACT_APP_GENERAL_GROUP_ID || '00g99pup684aR2yfe697'
  const reqWithGroup = { ...req, groupIds: [groupId] }
  return await apiGateway
    .post(`${ciamBase}/api/v1/users/?activate=true`, reqWithGroup)
    .then((rep) => {
      console.debug('[CiamCreateNewUser]', rep)
      return rep.data
    })
}

const CiamAddUserToGroup = async (userId: string) => {
  const groupId = process.env.REACT_APP_GENERAL_GROUP_ID || '00g99pup684aR2yfe697'
  return await apiGateway
    .put(`${ciamBase}/api/v1/groups/${groupId}/users/${userId}`)
    .then((rep) => {
      console.debug('[CiamAddUserToGroup]', rep)
      return rep.data
    })
}
const CiamUpdateUserProfile = async (req: any, userId?: string) => {
  if (!userId) return
  return await apiGateway.post(`${ciamBase}/api/v1/users/${userId}`, req).then((rep) => {
    console.debug('[CiamUpdateUserProfile]', rep)
    return rep.data
  })
}

const CiamRemoveUserGroup = async (userId: string) => {
  const groupId = process.env.REACT_APP_GENERAL_GROUP_ID || '00g99pup684aR2yfe697'
  return await apiGateway
    .delete(`${ciamBase}/api/v1/groups/${groupId}/users/${userId}`)
    .then((rep) => {
      console.debug('[CiamAddUserToGroup]', rep)
      return rep.data
    })
}

const EditUserCiamInfo = async (userId: string, ciamUserId) => {
  return await apiGateway
    .put(`${ciamBase}/api/v1/users/ciam/${userId}/${ciamUserId}`)
    .then((rep) => {
      console.debug('[EditUserCiamInfo]', rep)
      return rep.data
    })
}

const GetIbeamData = async (projectId: string) => {
  return await apiGateway.get(`api/v1/forms/external/request-log?project_id=${projectId}`).then((rep) => {
    return rep.data
  })
}

const AdminService = {
  GetUserList,
  GetUserListProject,
  GetUserForm,
  CreateUser,
  SaveCreateUser,

  GetContractUserList, // query contract user table
  GetProjectUserList, // query project user table

  SaveCreateProject,
  SaveCreateContract,

  SaveCreateOrganization,
  GetOrganizationMeta,

  CheckContractNoExists,
  CheckChitAccountNoExists,

  resetUserPassword,
  GetProjectForm,
  GetContractForm,
  GetProjectOptionList,

  GetProjectList,

  AddProjectUser,
  AddContractUser,
  RemoveProjectUser,
  RemoveContractUser,

  GetContractList,

  GetContractListByUserId,
  GetProjectListByUserId,

  SubmitEdpUrl,
  SubmitWeightUrl,
  GetOrganizationList,
  GetOrganizationForm,
  InviteUser,

  CiamGetUserProfile,
  CiamCreateNewUser,
  CiamAddUserToGroup,
  CiamRemoveUserGroup,
  CiamUpdateUserProfile,
  EditUserCiamInfo,

  GetIbeamData,
}

export default AdminService