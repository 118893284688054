import { DisposalGroundEnum, FormDrsModel } from '@services/model/form/form.DRS.model'
import { Control } from 'react-hook-form'
import BaseButton from '@components/button/BaseButton'
import styles from '@styles/baseButton.module.scss'
import FormTable from '@components/form/table/Table'
import { styled } from '@mui/material/styles'
import { Box, Button, Stack, Tabs, Tab, Typography } from '@mui/material'
import FormContainer from '@components/form/container'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import FormDrsService from '@services/formService/form.DRS.service'
import { useNavigate } from 'react-router-dom'
import { FormDrsGeneralOptionsModel } from '@services/model/form/form.DRS.model'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'
import { TableColumnProps } from '@components/form/table/model'
import testIsMobile from '@utils/testIsMobile'
import { FormStatusEnum } from '@services/model/form/form.model'
import { upMd } from '@hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import FormDialog from '@components/form/dialog_v2'
import { DialogContextProps, InitDialogContent } from '@components/form/table/model'
import DialogButton from '@components/button/DialogButton'
import getErrMsg from '@utils/getErrMsg'
import cleanString from '@utils/cleanString'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { AdsClick as ClickIcon } from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Output as ExtraIcon } from '@mui/icons-material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForward'
import { part1ReadOnlyStatus, part2HiddenStatus, remarksReadOnlyStatus } from './helpers'
import { FormDrsVerifyDrsChitsResModel } from '@services/model/form/form.DRS.upload.model'
import { Badge } from '@mui/material'
import { MdCheck } from 'react-icons/md'
import moment from 'moment'

const colorRed = '#D32F30'
const colorGreen = '#619B51'
const colorGrey = '#888888'
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  sx?: any
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#E8E9EA',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  textWrap: 'wrap',
}))

const DailyRecordSummaryPartB = ({
  onNextDisabled,
  getValues,

  useWatch,
  setValue,
  setError,
  control,
  formPermission,
  formStatus,
  drsGeneralOptions,
  globalState,
  onClickSaveRowBtn,
  trigger,
  reload,
  formId,
  isValidatedRef,
}: {
  onNextDisabled: (disabled: boolean) => void
  getValues: () => FormDrsModel
  useWatch: any
  setValue: any
  setError: any
  control: Control<FormDrsModel>
  formPermission: any
  formStatus: string
  drsGeneralOptions: FormDrsGeneralOptionsModel | undefined
  globalState: any
  onClickSaveRowBtn?: (...event: any[]) => void
  trigger: any
  reload: any
  formId: string | undefined
  isValidatedRef: any
}) => {
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)
  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const cicFormTableTheme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: '68px !important',
          },
          icon: {
            width: '40px',
            height: '90%',
            top: 'inherit',
            background: ' #FAFAFA 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '0px 5px 5px 0px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {},
        },
      },
    },
  })

  const cicFormTableThemeMobile = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {},
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {},
        },
      },
    },
  })

  const matchUpMd = upMd()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = testIsMobile()
  let index = 0
  const navigate = useNavigate()
  const { setRequest, isLoading } = useAPIFetch()
  const [panel, setPanel] = useState(0)
  const defaultWidth = isMobile ? '100%' : '170px' //TODO swap this 150px for up
  const defaultMinHeight = isMobile ? '40px' : '80px' //TODO swap this 80px for up
  let defaultVariant = isMobile ? { variant: 'standard' } : {}
  //matchUpMd
  const [pendingVerify, setPendingVerify] = useState(true)
  const part1ReadOnly = !formPermission?.canUpdate || part1ReadOnlyStatus(formStatus)
  const remarksReadOnly = !formPermission?.canUpdate || remarksReadOnlyStatus(formStatus)
  const part2hidden = panel === 1 || part2HiddenStatus(formStatus)

  const part2ReadOnly =
    !formPermission?.canUpdate ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondReview ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondApproved

  const part1Hidden = panel === 2

  const excludeOptionHidden =
    formStatus === FormStatusEnum.DailyRecordSummaryFirstReview ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondReview ||
    formStatus === FormStatusEnum.DailyRecordSummarySecondApproved

  const validateSectionHidden =
    formStatus !== FormStatusEnum.DailyRecordSummaryFirstApproved &&
    formStatus !== FormStatusEnum.DailyRecordSummarySecondRejected

  const reviewSectionHidden = formStatus !== FormStatusEnum.DailyRecordSummarySecondReview

  const designatedDisposalGroundOptions = drsGeneralOptions?.disposalGround?.filter(
    (option) => cleanString(option.value) !== cleanString(DisposalGroundEnum.other),
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (formStatus === 'FORM_DRS_FIRST_APPROVED' || formStatus === 'FORM_DRS_SECOND_REJECTED') {
      if (isValidatedRef.current) {
        onNextDisabled(false)
      } else {
        onNextDisabled(true)
      }
    } else {
      onNextDisabled(false)
    }
  }, [isValidatedRef.current, formStatus])

  const getErrorMessage = (item, overrideRef?) => {
    if (item?.reference) {
      return (
        <>
          {item.message}, do you mean{' '}
          <Button
            onClick={() => {
              let content = overrideRef ? overrideRef : item.reference
              copy(content)
              enqueueSnackbar(t('copied'), {
                variant: 'success',
              })
            }}>
            {overrideRef ? overrideRef : item.reference}
          </Button>
        </>
      )
    } else {
      return item?.message
    }
  }

  const handleTrigger = async () => {
    if (!(await trigger())) return
  }

  const handleLinkDrsItems = async (e) => {
    let values = getValues()

    if (formPermission?.canUpdate) {
      await FormDrsService.SaveDrsForm(values)
    }

    setRequest({
      callback: async () => {
        await FormDrsService.LinkDrsItems(values)
          .then((resp) => {
            if (resp && formId) {
              reload(formId)
            }
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }
  const handleUnlinkDrsItems = async (drsItemId?: string) => {
    let values = getValues()
    if (formPermission?.canUpdate) {
      await FormDrsService.SaveDrsForm(values)
    }
    const drsId = values?.id
    const drsItemsId = values?.drsItems
      ?.filter((item) => item.selected === true)
      ?.map((item) => item.id)
    let req = { drsId: drsId, drsItemsId: drsItemsId }
    let dialogTitle = t('Do you confirm to exclude the selected items?')
    if (drsItemId) {
      req = { drsId: drsId, drsItemsId: [drsItemId] }
      dialogTitle = t('Do you confirm to exclude this item?')
    }
    setDialogContext({
      isOpen: true,
      title: <Typography variant="body1">{dialogTitle}</Typography>,
      children: <></>,
      buttons: (
        <>
          <Stack direction="row" spacing={2}>
            <DialogButton
              onClick={() => {
                sendUnlinkRequest(formId, formStatus, req)
                handleDialogClose()
              }}>
              {t('common:buttons.confirm')}
            </DialogButton>
          </Stack>
          <Stack direction="row" spacing={2}>
            <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
          </Stack>
        </>
      ),
    })
  }

  const sendUnlinkRequest = async (formId, formStatus, req) => {
    let values = getValues()
    if (formPermission?.canUpdate) {
      await FormDrsService.SaveDrsForm(values)
    }
    setRequest({
      callback: async () => {
        await FormDrsService.UnlinkDrsItems(formId, formStatus, req)
          .then((resp) => {
            if (resp && formId) {
              reload(formId)
            }
          })
          .catch((err) => {
            enqueueSnackbar(getErrMsg(t, err), {
              variant: 'error',
            })
          })
      },
    })
  }

  const handleClickVerify = async (e) => {
    let values = getValues()
    const { dateOfDisposal } = values
    //todo remove this hotfix when backend ready
    const hktTime = moment.utc(dateOfDisposal).add(8, 'hours').format()

    console.debug('hktTime', hktTime)

    if (!formId || !dateOfDisposal) return
    const reqBody = {
      dateOfDisposal: hktTime,
      uuid: formId,
    }
    setRequest({
      callback: async () => {
        await FormDrsService.VerifyDrsChits(reqBody).then((resp) => {
          if (resp) {
            setValue('missingRecords', resp.missingRecords)
            setValue('extraRecords', resp.extraRecords)
            setValue('missingCounts', resp.missingCounts)
            setValue('extraCounts', resp.extraCounts)
            setValue('isMatched', resp.isMatched)
            if (resp.isMatched! === true) {
              setAutofillDisabled(false)
            }
          }
        })
      },
    })
  }

  const handleClickAutofill = async (e) => {
    let values = getValues()
    setRequest({
      callback: async () => {
        await FormDrsService.AutoFillDrsChits(values)
          .then((resp) => {
            if (resp?.passed === true) {
              onNextDisabled(false)
              enqueueSnackbar(t('Validate success'), {
                variant: 'success',
              })
              isValidatedRef.current = true
              reload(formId)
            } else {
              if (resp?.isDateOfDisposalPassed?.passed === false) {
                setError(`dateOfDisposal`, {
                  type: 'manual',
                  message: getErrorMessage(resp?.isDateOfDisposalPassed),
                })
                enqueueSnackbar(t(resp?.isDateOfDisposalPassed?.message), {
                  variant: 'error',
                })
              }
              resp.drsItemsResult?.map((item: any, index: number) => {
                if (item?.isChitNoPassed?.passed === false) {
                  setError(`drsItems.[${index}].chitNo`, {
                    type: 'manual',
                    message: getErrorMessage(item?.isChitNoPassed),
                  })
                }

                if (item?.isActualDisposalGroundPassed?.passed === false) {
                  setError(`drsItems.[${index}].actualDisposalGround`, {
                    type: 'manual',
                    message: getErrorMessage(
                      item?.isActualDisposalGroundPassed,
                      drsGeneralOptions?.disposalGround?.find(
                        (option) => option.key === item?.isActualDisposalGroundPassed?.reference,
                      )?.value,
                    ),
                  })
                }
                if (item?.isArrivalTimePassed?.passed === false) {
                  setError(`drsItems.[${index}].arrivedTime`, {
                    type: 'manual',
                    message: getErrorMessage(item?.isArrivalTimePassed),
                  })
                }

                if (item?.isVehicleRegistrationMarkPassed?.passed === false) {
                  setError(`drsItems.[${index}].vehicleRegistrationMark`, {
                    type: 'manual',
                    message: getErrorMessage(item?.isVehicleRegistrationMarkPassed),
                  })
                }
                if (item?.isNetWeightPassed?.passed === false) {
                  setError(`drsItems.[${index}].vehicleRegistrationMark`, {
                    type: 'manual',
                    message: getErrorMessage(item?.isNetWeightPassed),
                  })
                }
              })
              isValidatedRef.current = false
              reload(formId)
            }
          })
          .catch((err) => {
            isValidatedRef.current = false
            enqueueSnackbar(
              t(err.response?.data?.message ?? 'Please check if all information are filled in'),
              {
                variant: 'error',
              },
            )
          })
      },
    })
  }

  let columnData: TableColumnProps<FormDrsModel>[] = [
    {
      field: 'selected',
      displayName: t('forms:dailyRecordSummary.fields.select'),
      type: 'checkbox',
      minHeight: defaultMinHeight,
      width: '15px',
      minWidth: '15px',
      fieldProps: defaultVariant,
      hidden: excludeOptionHidden,
    },

    {
      field: 'chitNo',
      displayName: '',
      type: 'custom',
      minHeight: defaultMinHeight,
      width: '15px',
      minWidth: '15px',
      fieldProps: defaultVariant,
      hidden: excludeOptionHidden,
      render: (datum, datumIndex, setWarningText) => {
        //@ts-ignore
        const isExtra = getValues()?.extraRecords?.find((x) => {
          //@ts-ignore
          return x === datum?.chitNo
        })
        //@ts-ignore
        return (
          <>
            {isExtra ? (
              <Button
                sx={{
                  backgroundColor: '#d32f2f',
                  color: 'white',
                  '&:hover': { backgroundColor: '#d32f2f', opacity: 0.9 },
                }}
                disabled={excludeOptionHidden}
                onClick={() => {
                  if (datum['id']) {
                    handleUnlinkDrsItems(datum['id'])
                  }
                }}>
                {t('common:buttons.exclude')}
              </Button>
            ) : (
              <></>
            )}
          </>
        )
      },
    },

    {
      field: 'itemNo',
      displayName: t('forms:dailyRecordSummary.fields.itemNo'),
      type: 'custom',
      minHeight: defaultMinHeight,
      width: '5px',
      minWidth: '5px',
      fieldProps: defaultVariant,
      render: (datum, datumIndex, setWarningText) => {
        return <>{datumIndex + 1}</>
      },
      disable: false,
    },

    {
      field: 'chitNo',
      displayName: t('forms:dailyRecordSummary.fields.chitNo'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: defaultWidth,
      rows: 2,
      isDebounce: true,
      fieldProps: defaultVariant,
      disable: true,
    },
    {
      field: 'vehicleRegistrationMark',
      displayName: t('forms:dailyRecordSummary.fields.vehicleRegistrationMark'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: defaultWidth,
      rows: 2,
      fieldProps: defaultVariant,
      additionalChangeAction: async (value, datumIndex) => {
        isValidatedRef.current = false
      },
      disable: part1ReadOnly,
    },
    {
      field: 'dateOfDisposal',
      displayName: t('forms:dailyRecordSummary.fields.dateOfDisposal'),
      type: 'date',
      minHeight: defaultMinHeight,
      width: '200px',
      sx: { width: '100%' },
      disable: true,
      hidden: part1Hidden,
    },
    {
      field: 'departureTimeFromSite',
      displayName: t('forms:dailyRecordSummary.fields.departureTimeFromSite'),
      type: 'time',
      minHeight: defaultMinHeight,
      width: '200px',
      sx: { width: '100%' },
      additionalOnChangeAction: async (value, datumIndex) => {
        isValidatedRef.current = false
      },
      disable: part1ReadOnly,
      hidden: part1Hidden,
    },
    {
      field: 'approxVol',
      displayName: t('forms:dailyRecordSummary.fields.approxVol'),
      type: 'select',
      options: drsGeneralOptions?.approximateValue,
      minHeight: defaultMinHeight,
      width: defaultWidth,
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
      additionalOnChangeAction: async (value, datumIndex) => {
        isValidatedRef.current = false
      },
      hidden: part1Hidden,
    },
    {
      field: 'cDMaterialType',
      displayName: t('forms:dailyRecordSummary.fields.cDMaterialType'),
      type: 'select',
      options: drsGeneralOptions?.cDMaterialType,
      minHeight: defaultMinHeight,
      width: defaultWidth,
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
      additionalOnChangeAction: async (value, datumIndex) => {
        isValidatedRef.current = false
      },
      hidden: part1Hidden,
    },

    {
      field: 'disposalGround',
      displayName: t('forms:dailyRecordSummary.fields.disposalGround'),
      type: 'select',
      options: designatedDisposalGroundOptions,
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
      additionalOnChangeAction: async (value, datumIndex) => {
        isValidatedRef.current = false
      },
      hidden: part1Hidden,
    },
    {
      field: 'zoneWithinSite',
      displayName: t('forms:dailyRecordSummary.fields.zoneWithinSite'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: defaultWidth,
      rows: 2,
      fieldProps: defaultVariant,
      disable: part1ReadOnly,
      additionalOnChangeAction: async (value, datumIndex) => {
        isValidatedRef.current = false
      },
      hidden: part1Hidden,
    },
    {
      field: 'remarks',
      displayName: t('forms:dailyRecordSummary.fields.remarks.label'),
      tooltip: t('forms:dailyRecordSummary.fields.remarks.tooltip'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: defaultWidth,
      disable: remarksReadOnly,
      rows: 2,
    },
    {
      field: 'actualDisposalGround',
      displayName: t('forms:dailyRecordSummary.fields.actualDisposalGround'),
      type: 'select',
      options: designatedDisposalGroundOptions,
      minHeight: defaultMinHeight,
      width: defaultWidth,
      disable: true,
      hidden: part2hidden,
      fieldProps: defaultVariant,
    },
    {
      field: 'otherActualDisposalGround',
      displayName: t('forms:dailyRecordSummary.fields.otherActualDisposalGround'),
      type: 'string',
      minHeight: defaultMinHeight,
      width: defaultWidth,
      rows: 2,
      fieldProps: defaultVariant,
      disable: part2ReadOnly,
      hidden: part2hidden,
    },
    {
      field: 'arrivedTime',
      displayName: t('forms:dailyRecordSummary.fields.arrivedTime'),
      type: 'time',
      minHeight: defaultMinHeight,
      width: '200px',

      disable: true,
      hidden: part2hidden,
    },
    {
      field: 'wasteDepth',
      displayName: t('forms:dailyRecordSummary.fields.wasteDepth'),
      type: 'number',
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: true,
      hidden: part2hidden,
    },
    {
      field: 'weightIn',
      displayName: t('forms:dailyRecordSummary.fields.weightIn'),
      type: 'number',
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: true,
      hidden: part2hidden,
    },
    {
      field: 'weightOut',
      displayName: t('forms:dailyRecordSummary.fields.weightOut'),
      type: 'number',
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: true,
      hidden: part2hidden,
    },
    {
      field: 'netWeight',
      displayName: t('forms:dailyRecordSummary.fields.netWeight'),
      type: 'number',
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: true,
      hidden: part2hidden,
    },
    {
      field: 'permittedGrossVehicleWeight',
      displayName: t('forms:dailyRecordSummary.fields.permittedGrossVehicleWeight'),
      type: 'number',
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: true,
      hidden: part2hidden,
    },
    {
      field: 'overweight',
      displayName: t('forms:dailyRecordSummary.fields.overweight'),
      type: 'checkbox',
      minHeight: defaultMinHeight,
      width: '180px',
      fieldProps: defaultVariant,
      disable: true,
      hidden: part2hidden,
    },
    {
      field: 'exclude',
      displayName: t('common:buttons.exclude'),
      type: 'controlledCustom',
      hidden: excludeOptionHidden,
      controlledRender: (row, field, rowIndex) => (
        <Button
          sx={{
            backgroundColor: '#d32f2f',
            color: 'white',
            '&:hover': { backgroundColor: '#d32f2f', opacity: 0.9 },
          }}
          disabled={excludeOptionHidden}
          onClick={() => {
            if (row['id']) {
              handleUnlinkDrsItems(row['id'])
            }
          }}>
          {t('common:buttons.exclude')}
        </Button>
      ),
    },
  ]

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    color: '#393939',
    padding: theme.spacing(1),
    borderBottom: `1px solid #ccc`,
  }))

  const StyledTab = styled(Tab)(({ theme }) => ({
    display: 'flex',
    color: '#393939',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#619B51',
      borderRadius: '5px',
    },
  }))

  let sticky = true
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    }
  }

  const [drsItems] = useWatch({
    name: ['drsItems'],
    control: control,
  })

  useEffect(() => {
    console.debug('drsItems', drsItems)
  }, [drsItems])

  const [verifyDisabled, setVerifyDisabled] = useState(false)
  const [autofillDisabled, setAutofillDisabled] = useState(true)

  const CountBadge = (props: {
    fieldName: 'missingCounts' | 'extraCounts' | 'newRecordsCount' | 'updatedRecordsCount'
  }) => {
    const [count] = useWatch({
      name: [props.fieldName],
      control: control,
    })

    let bgColor = colorGrey
    let content = '-'
    if (count) {
      if (count === 0) {
        content = '' + count
        bgColor = colorGreen
      } else if (count > 0) {
        content = '' + count
        bgColor = colorRed
      }
    }
    return (
      <Box
        sx={{
          color: 'white',
          backgroundColor: bgColor,
          minWidth: '10px',
          padding: '0px 12px',
          borderRadius: ' 12px 12px',
        }}>
        {content}
      </Box>
    )
  }

  const RecordList = (props: {
    fieldName: 'missingRecords' | 'extraRecords' | 'newRecords' | 'updatedRecords'
  }) => {
    const [list] =
      useWatch({
        name: [props.fieldName],
        control: control,
      }) || []

    return <Box sx={{}}>{list?.join(', ')}</Box>
  }

  const VerificationResult = () => {
    const [isMatched, missingCounts, extraCounts] =
      useWatch({
        name: ['isMatched', 'missingCounts', 'extraCounts'],
        control: control,
      }) || []
    return (
      <>
        <AccordionSummary
          expandIcon={<></>}
          sx={{
            backgroundColor: autofillDisabled
              ? colorGrey
              : isMatched === true
              ? colorGreen
              : colorRed,
            color: '#ffffff',
            borderRadius: '12px 12px 0px 0px',
            fontWeight: 'bold',
          }}
          aria-controls="panel1d-content"
          id="panel1d-header">
          {t('forms:dailyRecordSummary.fields.verificationResult')}
        </AccordionSummary>
        <AccordionDetails>
          {autofillDisabled && isMatched === undefined ? (
            <Stack direction={'row'} gap={1}>
              <Box>
                <ClickIcon />
              </Box>
              <Box>{t('forms:dailyRecordSummary.fields.pleaseClickVerify')}</Box>
            </Stack>
          ) : (
            <>
              {isMatched === true ? (
                <Stack>
                  <Box sx={{ background: colorGreen }}>
                    <MdCheck size={30} />
                  </Box>
                  <Box>{t('forms:dailyRecordSummary.fields.noMissingExtraRecordMsg')}</Box>
                </Stack>
              ) : (
                <Stack direction="row" gap={1}>
                  <Box
                    sx={{
                      color: 'white',
                      background: colorRed,
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <MdCheck size={'30'} />
                  </Box>
                  <Stack>
                    {!!missingCounts && (
                      <Box>
                        {t('forms:dailyRecordSummary.fields.missingRecordsResultMsg', {
                          num: missingCounts,
                        })}
                      </Box>
                    )}

                    {!!extraCounts && (
                      <Box>
                        {t('forms:dailyRecordSummary.fields.extraRecordsResultMsg', {
                          num: extraCounts,
                        })}
                      </Box>
                    )}
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </AccordionDetails>
      </>
    )
  }

  const EpdVerificationResult = () => {
    const [newRecordsCount, updatedRecordsCount] =
      useWatch({
        name: ['newRecordsCount', 'updatedRecordsCount'],
        control: control,
      }) || []
    return (
      <>
        <AccordionSummary
          expandIcon={<></>}
          sx={{
            backgroundColor: colorGreen,
            color: '#ffffff',
            borderRadius: '12px 12px 0px 0px',
            fontWeight: 'bold',
          }}
          aria-controls="panel1d-content"
          id="panel1d-header">
          {t('forms:dailyRecordSummary.fields.epdChitVerResult')}
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
            <Box
              sx={{
                color: 'white',
                background: colorGreen,
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <MdCheck size={'30'} />
            </Box>
            <Stack>
              {!newRecordsCount && !updatedRecordsCount && (
                <Box>
                  {t('forms:dailyRecordSummary.fields.noNewUpdatedRecords', {
                    num: newRecordsCount,
                  })}
                </Box>
              )}
              {!!newRecordsCount && (
                <Box>
                  {t('forms:dailyRecordSummary.fields.newRecordsMsg', {
                    num: newRecordsCount || 0,
                  })}
                </Box>
              )}
              {!!updatedRecordsCount && (
                <Box>
                  {t('forms:dailyRecordSummary.fields.updatedRecordsMsg', {
                    num: updatedRecordsCount || 0,
                  })}
                </Box>
              )}
            </Stack>
          </Stack>
        </AccordionDetails>
      </>
    )
  }

  return (
    <ThemeProvider key={'table'} theme={matchUpMd ? cicFormTableTheme : cicFormTableThemeMobile}>
      <div className="drs-table-part-b">
        {sticky && matchUpMd && (
          <style>
            {/* {`
          .drs-table-part-b table {
            position: relative;
          }

          .drs-table-part-b table td:nth-child(1) ,  .drs-table-part-b table th:nth-child(1) {
            position: sticky !important;
            background-color: inherit;
            left: 0 !important;
            z-index: 1;
          }
          .drs-table-part-b table td:nth-child(2) ,  .drs-table-part-b table th:nth-child(2) {
            position: sticky !important;
            background-color: inherit;
            left: 20 !important;
            z-index: 1;
          }
          .drs-table-part-b table td:nth-child(3) ,  .drs-table-part-b table th:nth-child(3) {
            position: sticky !important;
            background-color: inherit;
            left:80 !important;
            z-index: 1;
          }
        `} */}
          </style>
        )}
        <FormContainer fkey={`$drs-a`}>
          <Stack
            sx={{ width: '100%' }}
            direction={upMd() ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}>
            <StyledTabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}>
              <StyledTab
                label={t('forms:dailyRecordSummary.all')}
                {...a11yProps(0)}
                onClick={() => {
                  console.debug('All')
                  setPanel(0)
                }}
              />
              <StyledTab
                label={t('forms:dailyRecordSummary.part1')}
                {...a11yProps(1)}
                onClick={() => {
                  console.debug('Part 1')
                  setPanel(1)
                }}
              />
              <StyledTab
                label={t('forms:dailyRecordSummary.part2')}
                {...a11yProps(2)}
                onClick={() => {
                  console.debug('Part 2')
                  setPanel(2)
                }}
              />
            </StyledTabs>
            <Stack spacing={2} direction={upMd() ? 'row' : 'column'}>
              <Button
                sx={{
                  backgroundColor: '#d32f2f',
                  color: 'white',
                  '&:hover': { backgroundColor: '#d32f2f', opacity: 0.9 },
                }}
                disabled={excludeOptionHidden}
                onClick={() => handleUnlinkDrsItems()}>
                {t('forms:dailyRecordSummary.excludeSelectedItems')}
              </Button>
              <Button
                sx={{
                  backgroundColor: globalState.headerColor,
                  color: 'white',
                  '&:hover': { backgroundColor: globalState.headerColor, opacity: 0.9 },
                }}
                disabled={excludeOptionHidden}
                onClick={handleLinkDrsItems}>
                {t('forms:dailyRecordSummary.linkItems')}
              </Button>
            </Stack>
          </Stack>
          {!validateSectionHidden && (
            <>
              <Box p={2}>
                <Box sx={{ fontWeight: 'bold', fontSize: '24px', width: '100%' }}>
                  {t('forms:dailyRecordSummary.fields.referenceOnlineEPDRecords')}
                </Box>
                <Stack pt={1} gap={2}>
                  <Box>
                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                      <Box width={'200px'} sx={{ textWrap: 'wrap' }}>
                        {t('forms:dailyRecordSummary.fields.missingOrIncorrectChitNum')}:
                      </Box>
                      <BaseButton
                        displayText={t('common:buttons.verify')}
                        disabled={verifyDisabled}
                        className={styles.formButton}
                        sx={{ width: '200px' }}
                        onClickHandler={handleClickVerify}
                      />
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                      <Box width={'200px'} sx={{ textWrap: 'wrap' }}>
                        {t('forms:dailyRecordSummary.fields.part2Data')}:
                      </Box>
                      <BaseButton
                        displayText={t('buttons.autofill')}
                        disabled={autofillDisabled}
                        className={styles.formButton}
                        sx={{ width: '200px' }}
                        onClickHandler={handleClickAutofill}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ maxWidth: '805px' }}>
                  <Accordion
                    expanded={true}
                    defaultExpanded
                    sx={{ borderRadius: '12px 12px 0px 0px' }}>
                    <VerificationResult />
                  </Accordion>
                  <Accordion>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <Stack direction={'row'} gap={1}>
                        <Typography>
                          {t('forms:dailyRecordSummary.fields.missingRecords')}
                        </Typography>
                        <CountBadge fieldName={'missingCounts'} />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <RecordList fieldName={'missingRecords'} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Stack direction={'row'} gap={1}>
                        <Typography>{t('forms:dailyRecordSummary.fields.extraRecords')}</Typography>
                        <CountBadge fieldName={'extraCounts'} />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <RecordList fieldName={'extraRecords'} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </>
          )}
          {!reviewSectionHidden && (
            <>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ maxWidth: '805px' }}>
                  <Accordion
                    expanded={true}
                    defaultExpanded
                    sx={{ borderRadius: '12px 12px 0px 0px' }}>
                    <EpdVerificationResult />
                  </Accordion>
                  <Accordion>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <Stack direction={'row'} gap={1}>
                        <Typography>{t('forms:dailyRecordSummary.fields.newRecords')}</Typography>
                        <CountBadge fieldName={'newRecordsCount'} />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <RecordList fieldName={'newRecords'} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <Stack direction={'row'} gap={1}>
                        <Typography>
                          {t('forms:dailyRecordSummary.fields.updatedRecords')}
                        </Typography>
                        <CountBadge fieldName={'updatedRecordsCount'} />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <RecordList fieldName={'updatedRecords'} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </>
          )}
          <FormTable
            hiddenItemNo={true}
            onClickSaveRowBtn={onClickSaveRowBtn}
            isMobile={isMobile}
            uneditable={!formPermission?.canUpdate}
            removeAddButton={true}
            disableDeleteRowButton={true}
            useWatch={useWatch}
            getValues={getValues}
            setValue={setValue}
            removeOverflow={true}
            key={`table-drs-b-${index}`}
            columns={columnData}
            height={50}
            additionalAppendAction={(event) => {
              isValidatedRef.current = false
            }}
            fieldArrayProps={{
              name: 'drsItems',
              control: control,
            }}
          />
          {/* </TabPanel> */}
        </FormContainer>
        <FormDialog
          key={'dialog'}
          open={dialogContext.isOpen}
          onClose={handleDialogClose}
          toolbarStyle={dialogContext.toolbarStyle}
          title={dialogContext.title}
          buttons={dialogContext.buttons}
          children={dialogContext.children}
        />
      </div>
    </ThemeProvider>
  )
}
export default DailyRecordSummaryPartB