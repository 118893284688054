import { Box, Drawer, Typography, useMediaQuery, useTheme, IconButton, Stack } from '@mui/material'
import { useContext } from 'react'
import MenuList from './menuList'
import { GlobalContext } from '@providers/globalStore'
import styles from '@styles/sidebar.module.scss'
import home from '../../assets/img/home.svg'
import noty from '../../assets/img/notification.svg'
import usersIc from '../../assets/img/users.svg'
import userIc from '../../assets/img/user.svg'
import logoutIc from '../../assets/img/logout.svg'
import { Link } from 'react-router-dom'
import toplogo from '../../assets/img/top-logo.png'
import ciclogo from '../../assets/img/cic.png'
import adminIcon from '../../assets/img/setting.svg'
import SelectContract from './selectContract'
export const DrawerWidth = 300
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import useLocalStorage from '@hooks/useLocalStorage'
import { InitUserInfo, UserInfo } from '@services/model/user.model'
import { RightsCategory } from '@services/model/form/form.model'
import getUserRightByRole from '@utils/getUserRightByRole'
import { LanguageContext } from '@providers/languageProvider'
import {
  canCreateOrgUserRoles,
  canCreateProjAssignOrgUsersRoles,
  cicAdminRoles,
  hasUserAdminRightRoles,
} from '@services/model/admin.model'
import { useOktaAuth } from '@okta/okta-react'
import { LanguageEnum } from '@i18n/index'

const Sidebar = function ({isMobile}:{isMobile?:boolean}) {
  const { state: globalState, dispatch, userInfo, hasRightByCatAndCode } = useContext(GlobalContext)
  const { language, changeLanguage } = useContext(LanguageContext)
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const navigate = useNavigate()
  const { authState, oktaAuth } = useOktaAuth()

  const isCorsError = (err) =>
    err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch'

  const logout = async () => {
    const basename = window.location.origin
    localStorage.clear()
    sessionStorage.clear()
    try {
      await oktaAuth.signOut().then(() => {
        setLoginUser({ ...InitUserInfo })
      })
    } catch (err) {
      console.error('Error on logout', err)
      if (isCorsError(err)) {
      } else {
        throw err
      }
    }
  }

  // const { keycloak, initialized } = useKeycloak()
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)

  const handleLeftDrawerToggle = () => {
    dispatch({ type: 'toggleLeftDrawer' })
  }

  const handleClick = (event) => {
    matchUpMd ? NavigateTo(navigate, '/') : handleLeftDrawerToggle()
  }

  const { hasUserRight: hasViewAdminPageRight } = getUserRightByRole(userInfo, globalState, [
    ...cicAdminRoles,
    ...canCreateProjAssignOrgUsersRoles,
    ...hasUserAdminRightRoles,
    ...canCreateOrgUserRoles,
  ])

  const drawer = (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
          zIndex: 999,
          textAlign: 'center',
          paddingBottom: isMobile ? '0':'1rem'
        }}
        className={styles.sideMenuHeader}>
        <Box
          component="img"
          className={styles.logoImage}
          alt="Smart Waste Management Tool"
          src={toplogo}
          sx={!isMobile ?{paddingTop: '20px'}: { paddingTop: '1rem'}}
          onClick={handleClick}
        />

       {!isMobile && <SelectContract />}
      </Box>
      <Box
        component="div"
        sx={{
          flex: 1,
          paddingLeft: '16px',
          borderTop: '1px solid #ccc',
          marginRight: '1rem',
          paddingTop: '1rem',
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        <MenuList key={'ml-1'} />
      </Box>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#fff',
          zIndex: 99,
          padding: '0 .5rem',
        }}>
     {   !isMobile &&  <Box
          sx={{
            background: '#ebebeb',
            display: 'flex',
            padding: '10px',
            justifyContent: 'center',
          }}>
    
        <Box sx={{ padding: '0 8px', justifyContent: 'center', textAlign: 'center' }}>
            <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.firstName}</Typography>
            <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.position}</Typography>
          </Box>
        </Box>}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ padding: '10px' }}>
            <Link to="/">
              <Box component="img" alt="Home" src={home} sx={{ padding: '0 5px' }} />
            </Link>
            <Link to="/notifications">
              <Box component="img" alt="Notification" src={noty} sx={{ padding: '0 5px' }} />
            </Link>
            {hasViewAdminPageRight ? (
              <Link to="/admin">
                <Box
                  component="img"
                  alt="Project Settings"
                  src={adminIcon}
                  sx={{ padding: '0 5px' }}
                />
              </Link>
            ) : (
              <></>
            )}
            <Link to={`admin/user/${userInfo?.uuid}?profile=true`}>
              <Box component="img" alt="User" src={usersIc} sx={{ padding: '0 5px' }} />
            </Link>
          </Box>
          <Stack gap={'2px'} direction="row" alignItems="baseline" justifyContent={'center'}>
            <IconButton
              size="small"
              sx={{
                p: 0,
              }}
              onClick={() => {
                changeLanguage(LanguageEnum.ENGLISH)
                console.debug('change language to zh-Hant')
              }}>
              EN
            </IconButton>
            <IconButton
              size="small"
              sx={{
                p: 0,
              }}
              onClick={() => {
                changeLanguage(LanguageEnum.TRADITIONAL_CHINESE)
                console.debug('change language to zh-Hant')
              }}>
              繁
            </IconButton>
            <IconButton
              size="small"
              sx={{
                p: 0,
              }}
              onClick={() => {
                changeLanguage(LanguageEnum.SIMPLIFIED_CHINESE)
                console.debug('change language to zh-Hant')
              }}>
              簡
            </IconButton>
          </Stack>
          <Box>
            <IconButton
              sx={{
                p: 0,
              }}
              onClick={() => {
                console.debug('logout')
                logout()
              }}>
              <img src={logoutIc} alt="menu" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  )

  return (
    <Box component="nav" aria-label="mailbox folders">
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={globalState.leftDrawerOpened}
        onClose={() => {
          dispatch({ type: 'toggleLeftDrawer' })
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: DrawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
          },
        }}
        ModalProps={{ keepMounted: true }}>
        {drawer}
      </Drawer>
    </Box>
  )
}

export default Sidebar
